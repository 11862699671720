interface Props {
  createAt: Date;
}

export default class GameRecordSessionRecord {
  readonly createAt: Date;

  constructor({ createAt }: Props) {
    this.createAt = createAt;
  }
}
