<template>
  <component :is="IconComponent" v-if="IconComponent" />
</template>
<script setup lang="ts">
import { shallowRef, onMounted } from 'vue';
import type { Ref } from 'vue';

interface Props {
  name: string;
}

const { name } = defineProps<Props>();
const IconComponent: Ref<null | {}> = shallowRef(null);

onMounted(() => loadIcon(name));

const loadIcon = async (name: string): Promise<void> => {
  IconComponent.value = (await import(`@/assets/icons/${name}.svg`)).default;
};
</script>
