import { defineStore } from 'pinia';
import { shallowRef } from 'vue';
import api from '@/common/axios/axios';
import router from '@/router/index';
import emitter from '@/common/eventBus';
import GameQuestionSessionAnswer from '@/domain/entities/flow/GameQuestionSessionAnswer';
import GameMessage from '@/domain/entities/flow/GameMessage';
import Flow from '@/domain/aggregates/Flow';
import Stage from '@/domain/entities/flow/Stage';
import Badge from '@/domain/entities/flow/Badge';
import GameQuestion from '@/domain/entities/flow/GameQuestion';
import { useSettingsStore } from '@/stores/settingsStore';
import { useSessionStore } from '@/stores/sessionStore';
import { useLayoutStore } from '@/stores/layoutStore';
import { FINISH, GAME, WELCOME, END_OF_TIME } from '@/router/routes.names';
import { START_COUNTDOWN } from '@/names/events.names';
import GameVideo from '@/domain/entities/flow/GameVideo';
import { VideoPreloader } from '@/common/videoPreloader';

interface State {
  flow: Flow | null;
  videoPreloader: VideoPreloader;
}

export const useFlowStore = defineStore('flowStore', {
  // @ts-ignore
  state: (): State => ({
    // @ts-ignore
    flow: shallowRef(null),
    videoPreloader: new VideoPreloader(),
  }),
  actions: {
    redirectRoute(): {
      name: string;
      params: {
        recruitmentProcessId: string | null;
        sessionId: string | null;
        stageNumber?: number | null;
      };
    } {
      const settingsStore = useSettingsStore();
      const sessionStore = useSessionStore();

      const basicRouteParams = {
        recruitmentProcessId: settingsStore.recruitmentProcessId,
        sessionId: sessionStore.sessionId,
      };

      if (this.areAllStagesCompleted || sessionStore.isGameFinished) {
        return { name: FINISH, params: basicRouteParams };
      }

      if (sessionStore.hasGameTimeLimit && sessionStore.remainingTime <= 0) {
        return { name: END_OF_TIME, params: basicRouteParams };
      }

      if (!sessionStore.isGameStarted && router.currentRoute.value.name !== WELCOME) {
        return { name: WELCOME, params: basicRouteParams };
      }

      return {
        name: GAME,
        params: {
          ...basicRouteParams,
          stageNumber: this.currentStageIndex + 1,
        },
      };
    },
    async markStageAsCompleted(stage: Stage): Promise<void> {
      const response = await api.post(`game-stage/${stage.id}/game-session-stage`);
      const completedAt = new Date(response.data.completed_at);
      this.setStage(stage.markAsCompleted(completedAt));
    },
    async markStageBadgeAsCompleted(stage: Stage): Promise<void> {
      this.setStage(stage.markBadgeAsCompleted());
    },
    async markQuestionAsAnswered(question: GameQuestion): Promise<void> {
      const chosenAnswers = question.chosenAnswersRequestData();
      const response = await api.post('session-question-answer', {
        question_id: question.questionId,
        answers: chosenAnswers,
      });
      const sessionAnswers = response.data.data.map(
        (sessionAnswer: any) =>
          new GameQuestionSessionAnswer({
            createAt: new Date(sessionAnswer.created_at),
            answer: sessionAnswer.answer,
          })
      );
      this.setStage(question.updateSessionAnswers(sessionAnswers));
    },
    async uploadRecordedData({ data }: any): Promise<void> {
      const layoutStore = useLayoutStore();
      const sessionStore = useSessionStore();
      await api.post('session-record', data, {
        onUploadProgress: ({ loaded, total = 0 }) => {
          const progress = Math.round((loaded / total) * 100);
          layoutStore.setProgress(progress);
        },
      });
      if (sessionStore.hasGameTimeLimit) {
        emitter.emit(START_COUNTDOWN);
      }
    },
    async stopGameTime() {
      await api.post('session/pause');
    },
    async redirectToCurrentStage(): Promise<void> {
      const newRoute = this.redirectRoute();
      if (
        JSON.stringify(newRoute) !==
        JSON.stringify({
          name: router.currentRoute.value.name,
          params: router.currentRoute.value.params,
        })
      ) {
        await router.push(newRoute);
      }
    },
    preloadStageVideo(stage: GameVideo): void {
      this.videoPreloader.preload(Object.values(stage.files));
    },
    setFlow(flow: Flow): void {
      this.flow = flow;
    },
    setStage(stage: any) {
      if (this.flow) {
        this.flow = this.flow.updateStage(stage);
      }
    },
  },
  getters: {
    currentStageIndex: (state: State): number => state.flow?.currentStageIndex() ?? 0,
    currentStage: (state: State): Stage | null => state.flow?.currentStage() ?? null,
    nextStage: (state: State): Stage | null => state.flow?.nextStage() ?? null,
    areAllStagesCompleted: (state: State): boolean => state.flow?.areAllStagesCompleted() ?? false,
    percentageCompleted: (state: State): number =>
      state.flow !== null ? state.flow?.percentageCompleted() : 0,
    questionsCount: (state: State): number => state.flow?.questionsCount() ?? 0,
    completedQuestionsCount: (state: State): number => state.flow?.completedQuestionsCount() ?? 0,
    messagesToCurrentStage: (state: State): GameMessage[] =>
      state.flow?.messagesToCurrentStage() ?? [],
    badges: (state: State): Badge[] => (state.flow !== null ? state.flow?.badges() : []),
    completedBadgesCount: (state: State): number => state.flow?.completedBadgesCount() ?? 0,
    hasGameRecords: (state: State): boolean => state.flow?.hasGameRecords() ?? false,
    hasQuestions: (state: State): boolean => state.flow?.hasQuestions() ?? false,
  },
});
