export default {
  name: '313C',
  recruitment_game: 'Test de recrutement',
  back: 'Retour',
  cancel: 'Annuler',
  continue: 'Continuer',
  skip: 'Ignorer',
  start: 'Démarrer',
  close: 'Fermez',
  help: 'Aide',
  time_left: 'Temps restant',
  progress: 'Progrès',
  privacy_policy: 'Politique de confidentialité ',
  cookies_policy: 'Politique de cookies et Conditions',
  faq: 'Questions et réponses ',
  messages: 'Messages',
  unread: 'courrier non lu',
  start_recording: "Démarrer l'enregistrement",
  stop_recording: "Arrêter l'enregistrement",
  remove_record: "Supprimer l'enregistrement",
  play_video: 'Lire',
  stop_video: 'Arrêter',
  mute_video: 'Muet',
  unmute_video: 'Activer le son',
  replay_video: 'Rejouer',
  choose: 'Choisissez',
  badges: {
    title: 'Insignes',
    badge: 'Insigne',
    my_badges: 'Mes Insignes',
    received: 'Vous recevez un insigne',
    continue: 'Jouez encore',
    show_badges: 'Voir les insignes',
    show_badge: 'Voir la récompense',
  },
  record_test: {
    intro: 'TEST DES RÉGLAGES DE LA CAMÉRA ET DU MICROPHONE',
    content:
      "Avant de commencer l'outil, testez vos réglages. Dans le champ ci-dessous, vous devriez voir l'image de votre caméra. Si vous la voyez, cela signifie que vos réglages sont probablement corrects - pour en être sûr, enregistrez et lisez une vidéo. Si vous ne voyez pas l'image de la caméra, vous ne pouvez pas lire l'enregistrement, ou si vous avez d'autres doutes, cliquez sur le bouton « DÉPANNAGE ».",
    troubleshoot: 'DÉPANNAGE',
  },
  questions: {
    progress: 'Question',
    accept: 'Approuver',
    question_has_already_been_answered: 'Cette question a déjà été répondue.',
  },
  not_found: {
    code: '404',
    title: "La page n'existe pas",
    content:
      "La page que vous recherchez n'a pas été trouvée. Il se peut qu'elle ait été supprimée, son nom modifié ou qu'elle n'ait jamais existé.",
  },
  phone: 'Numeero de telephone',
  select_language: 'Choisir la langue / Choose language',
  title:
    "Ce site utilise des cookies pour vous offrir une expérience utilisateur exceptionnelle. En continuant à naviguer sur ce site, vous acceptez l'utilisation",
  link: 'de cookies par nous.',
  button: 'Fermez',
  warning: {
    title: "Une erreur s'est produite",
    content: "Essayez à nouveau et si l'erreur persiste, contactez-nous par",
    content_without_email:
      "Essayez à nouveau et si l'erreur persiste, contactez le support technique.",
    confirm: 'Fermez',
  },
  end_time: {
    title: 'Signaler une erreur',
    content:
      "Si une erreur se produit pendant votre jeu, veuillez nous contacter à l'adresse email suivante:",
  },
  record_video_error: {
    title: 'Nincs hozzáférés a kamerához és/vagy mikrofonhoz.',
    content:
      'A feladat befejezéséhez módosítsa a beállításait, és engedélyezze az alkalmazásnak a kamera és/vagy mikrofon használatát.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Appareil non pris en charge</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Pour continuer, veuillez utiliser un <strong>autre appareil et/ou un autre navigateur.</strong></p>
        <p>L'outil de recrutement que vous avez l'intention d'utiliser comprend au moins une tâche qui nécessite l'enregistrement de votre réponse au format vidéo. Malheureusement, tous les appareils et/ou navigateurs ne permettent pas de le faire correctement.</p>
        <p><strong>Si vous voyez cet écran, cela signifie que votre appareil et/ou votre navigateur ne garantissent pas l'enregistrement correct et l'envoi d'un fichier vidéo.</strong> Ci-dessous, vous trouverez les paramètres recommandés.</p>
        <br>
        <p><strong>Appareils de bureau:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Appareils mobiles:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Êtes-vous sûr de vouloir ignorer la tâche?',
    content:
      "<p>L'enregistrement d'une réponse vidéo pour cette tâche n'est pas obligatoire, mais recommandé - cela nous permettra de mieux vous connaître. Êtes-vous sûr de vouloir ignorer l'enregistrement d'une réponse vidéo?</p>",
  },
  skip_stage_with_record: {
    title: 'Êtes-vous sûr de vouloir ignorer la tâche?',
    content:
      "<p>Vous avez l'intention de sauter l'enregistrement de la réponse vidéo enregistrée - elle ne sera pas accessible à la personne qui mène le processus de recrutement. Le téléchargement d'une réponse vidéo pour cette tâche n'est pas obligatoire, mais recommandé - cela nous aidera à mieux vous connaître. Êtes-vous sûr de vouloir sauter l'enregistrement de la réponse vidéo enregistrée?</p>",
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Dépannage de la caméra et du microphone</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Si l'enregistrement ou la lecture est incorrect, vous pouvez suivre quelques étapes qui devraient résoudre le problème. Les causes les plus courantes de problèmes proviennent des paramètres de l'appareil, y compris d'autres programmes ou applications utilisant simultanément la caméra et/ou le microphone.</p>
      <p>Solutions possibles:</p>
      <ol>
        <li>Assurez-vous que le navigateur a les autorisations pour utiliser le microphone et la caméra. Si ce n'est pas le cas, modifiez les paramètres et rafraîchissez la page.</li>
        <li>Assurez-vous qu'aucune autre application ou programme n'utilise actuellement la caméra/le microphone (par exemple, MS Teams, Photo Booth ou FaceTime) - fermez les applications qui bloquent la possibilité d'utiliser la caméra par le navigateur et rafraîchissez la page.</li>
        <li>Vérifiez si votre navigateur est mis à jour vers la dernière version - si ce n'est pas le cas, effectuez une mise à jour et relancez le test.</li>
        <li>Si les étapes ci-dessus n'ont pas aidé, essayez d'utiliser un autre navigateur ou appareil.</li>
      </ol>
      <p>Si le problème persiste, faites-le nous savoir à { supportEmail } en envoyant des informations sur l'appareil et le navigateur sur lesquels le problème se produit.</p>
      </div>
      <hr>
    `,
  },
  recaptcha: 'Cochez Recaptcha',
  phone_incorrect: 'Veuillez entrer un numéro de téléphone valide',
  rotate_phone_video: 'Tournez votre téléphone horizontalement pour voir la vidéo en plus grand',
  rotate_phone_image: "Tournez votre téléphone horizontalement pour voir l'image en plus grand",
};
