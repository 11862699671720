import { immerable, produce } from 'immer';
import GameQuestion from '@/domain/entities/flow/GameQuestion';
import ClosedGameQuestionAnswer from '@/domain/entities/flow/ClosedGameQuestionAnswer';
import Badge from '@/domain/entities/flow/Badge';
import GameQuestionSessionAnswer from '@/domain/entities/flow/GameQuestionSessionAnswer';

interface Props {
  id: string;
  type: string;
  order: number;
  body: string;
  answers: ClosedGameQuestionAnswer[];
  completedAt: Date | null;
  questionId: string;
  badge: Badge | null;
  instruction: string;
  imageUrl: string;
  sideImageUrl: string;
  mobileSideImageUrl: string;
  header: string;
}

export default class ClosedGameQuestion extends GameQuestion {
  [immerable] = true;

  readonly answers: ClosedGameQuestionAnswer[];

  constructor({
    id,
    type,
    order,
    body,
    answers,
    completedAt,
    questionId,
    badge,
    instruction,
    imageUrl,
    sideImageUrl,
    mobileSideImageUrl,
    header,
  }: Props) {
    super({
      id,
      type,
      order,
      body,
      completedAt,
      questionId,
      badge,
      instruction,
      imageUrl,
      sideImageUrl,
      mobileSideImageUrl,
      header,
    });

    this.answers = answers;
  }

  hasChosenAnswer(): boolean {
    return this.answers.some((answer: ClosedGameQuestionAnswer) => answer.chosen);
  }

  hasSessionAnswer(): boolean {
    return this.answers.some((answer: ClosedGameQuestionAnswer) => answer.sessionAnswer);
  }

  markBadgeAsCompleted(): ClosedGameQuestion {
    return produce(this, (draft: any) => {
      const chosenAnswer = draft.chosenAnswer();
      if (chosenAnswer && chosenAnswer.badge) {
        const answerIndex = draft.answers.findIndex(
          (findAnswer: ClosedGameQuestionAnswer) => findAnswer.id === chosenAnswer.id
        );
        draft.answers[answerIndex] = draft.answers[answerIndex].markBadgeAsCompleted();

        return;
      }
      if (!draft.badge) return;
      draft.badge = draft.badge.markAsCompleted();
    });
  }

  chosenAnswer(): ClosedGameQuestionAnswer | undefined {
    return this.answers.find((answer) => answer.chosen);
  }

  chooseAnswer(answer: ClosedGameQuestionAnswer): ClosedGameQuestion {
    return produce(this, (draft) => {
      draft.answers = draft.answers.map((mapAnswer) =>
        mapAnswer.id === answer.id ? mapAnswer.choose() : mapAnswer.unChoose()
      );
    });
  }

  chosenAnswersRequestData(): { answer_id: string; answer: null }[] {
    const chosenAnswer = this.chosenAnswer();
    return chosenAnswer ? [{ answer_id: chosenAnswer.id, answer: null }] : [];
  }

  updateSessionAnswers(sessionAnswers: GameQuestionSessionAnswer[]): ClosedGameQuestion {
    const sessionAnswer = sessionAnswers.find((findSessionAnswer) => findSessionAnswer);

    return produce(this, (draft) => {
      draft.answers = draft.answers.map((mapAnswer) =>
        mapAnswer.id === this.chosenAnswer()?.id && sessionAnswer
          ? mapAnswer.updateSessionAnswer(sessionAnswer)
          : mapAnswer
      );
    });
  }

  possibleBadge(): Badge | null {
    if (this.badge) return this.badge;

    return this.answers.find((answer) => answer.badge)?.badge ?? null;
  }
}
