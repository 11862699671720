type Props = {
  invalid?: boolean;
  disabled?: boolean;
};

type Context = {
  checked: boolean;
};

export default {
  root: {
    class: [
      'relative',

      // Alignment
      'inline-flex',
      'align-bottom',

      // Size
      'w-6',
      'h-6',

      // Misc
      'cursor-pointer',
      'select-none',
    ],
  },
  box: ({ props, context }: { props: Props; context: Context }) => ({
    class: [
      // Alignment
      'flex',
      'items-center',
      'justify-center',

      // Size
      'w-6',
      'h-6',

      // Shape
      'rounded-md',
      'border-2',

      // Colors
      {
        'border-neutral-200 bg-white': !context.checked && !props.invalid,
        'border-neutral-400 bg-neutral-400 text-white': context.checked,
      },

      // Invalid State
      { 'border-red-500': props.invalid },

      // States
      {
        'peer-hover:border-neutral-300': !props.disabled && !context.checked && !props.invalid,
        'peer-focus-visible:border-neutral-500 peer-focus-visible:ring-2 peer-focus-visible:ring-neutral-400/20':
          !props.disabled,
        'cursor-default opacity-60': props.disabled,
      },

      // Transitions
      'transition-colors',
      'duration-200',
    ],
  }),
  input: {
    class: [
      'peer',

      // Size
      'w-full',
      'h-full',

      // Position
      'absolute',
      'top-0 left-0',
      'z-10',

      // Spacing
      'p-0',
      'm-0',

      // Shape
      'opacity-0',
      'rounded-md',
      'outline-none',
      'border-2 border-neutral-200',

      // Misc
      'appearance-none',
      'cursor-pointer',
    ],
  },
  icon: {
    class: [
      // Font
      'text-base leading-none',

      // Size
      'w-4',
      'h-4',

      // Colors
      'text-white',

      // Transitions
      'transition-all',
      'duration-200',
    ],
  },
};
