import swal from 'sweetalert2';
import i18n from '@/common/i18n';
import type { SweetAlertResult } from 'sweetalert2';
import warningIcon from '@/assets/images/warning.png';
import { useSettingsStore } from '@/stores/settingsStore';

const ALERT_PADDING = '25px 5px 20px';
const ICON_WIDTH = '100';
const ICON_HEIGHT = '100';

interface AlertParams {
  confirmButtonText?: string;
  cancelButtonText?: string;
  title?: string;
  html?: string;
  [key: string]: any;
}

const getCommonParams = () => {
  const settingsStore = useSettingsStore();
  return {
    confirmButtonColor: settingsStore.interfaceColor,
    padding: ALERT_PADDING,
    customClass: {
      confirmButton: 'swal2-confirm-custom',
      cancelButton: 'swal2-cancel-custom',
    },
  };
};

const filterValidParams = (params: AlertParams): AlertParams => {
  const validKeys = [
    'title',
    'text',
    'html',
    'icon',
    'confirmButtonText',
    'cancelButtonText',
    'showCancelButton',
    'showConfirmButton',
    'confirmButtonColor',
    'cancelButtonColor',
    'customClass',
    'imageUrl',
    'imageWidth',
    'imageHeight',
    'width',
    'padding',
    'reverseButtons',
    'didOpen',
  ];
  return Object.keys(params).reduce((filteredParams, key) => {
    if (validKeys.includes(key)) {
      filteredParams[key] = params[key];
    }
    return filteredParams;
  }, {} as AlertParams);
};

export const warningAlert = (params?: AlertParams): Promise<SweetAlertResult> => {
  const settingsStore = useSettingsStore();
  const defaultParams: AlertParams = {
    imageUrl: warningIcon,
    imageWidth: ICON_WIDTH,
    imageHeight: ICON_HEIGHT,
    width: '440px',
    title: i18n.global.t('warning.title'),
    html: settingsStore.supportEmail
      ? `${i18n.global.t('warning.content')} <a href="mailto:${settingsStore.supportEmail}" />${settingsStore.supportEmail}</a>`
      : i18n.global.t('warning.content_without_email'),
    confirmButtonText: i18n.global.t('warning.confirm'),
    ...getCommonParams(),
  };
  return swal.fire(filterValidParams({ ...defaultParams, ...params }));
};

export const wrongDeviceAlert = (params?: AlertParams): Promise<SweetAlertResult> => {
  const defaultParams: AlertParams = {
    width: '800px',
    confirmButtonText: i18n.global.t('back'),
    html: `
      <div style="text-align: left">
        ${i18n.global.t('unsupported_os.content')}
      </div>
      `,
    ...getCommonParams(),
  };
  return swal.fire(filterValidParams({ ...defaultParams, ...params }));
};

export const skipStageAlert = (
  hasRecordedData: boolean,
  params?: AlertParams
): Promise<SweetAlertResult> => {
  const settingsStore = useSettingsStore();
  const defaultParams: AlertParams = {
    confirmButtonText: i18n.global.t('skip'),
    cancelButtonText: i18n.global.t('back'),
    showCancelButton: true,
    reverseButtons: true,
    width: '800px',
    cancelButtonColor: 'white',
    title: hasRecordedData
      ? i18n.global.t('skip_stage_with_record.title')
      : i18n.global.t('skip_stage_without_record.title'),
    html: `
      <div style="text-align: center">
        ${hasRecordedData ? i18n.global.t('skip_stage_with_record.content') : i18n.global.t('skip_stage_without_record.content')}
      </div>
      `,
    didOpen: () => {
      const customConfirmButton = document.querySelector('.swal2-confirm-custom') as HTMLDivElement;
      const customCancelButton = document.querySelector('.swal2-cancel-custom') as HTMLDivElement;
      if (customConfirmButton !== null) {
        customConfirmButton.style.outlineColor = settingsStore.interfaceColor;
      }
      if (customCancelButton !== null) {
        customCancelButton.style.border = `1px solid ${settingsStore.interfaceColor}`;
        customCancelButton.style.color = settingsStore.interfaceColor;
        customCancelButton.style.outlineColor = settingsStore.interfaceColor;
      }
    },
    ...getCommonParams(),
  };
  return swal.fire(filterValidParams({ ...defaultParams, ...params }));
};

export const instructionCameraAlert = (params?: AlertParams): Promise<SweetAlertResult> => {
  const settingsStore = useSettingsStore();
  const defaultParams: AlertParams = {
    confirmButtonText: i18n.global.t('back'),
    width: '1000px',
    html: `
      <div style="text-align: left">
        ${i18n.global.t('instruction_camera.content', { supportEmail: `<a href="mailto:${settingsStore.supportEmail}" />${settingsStore.supportEmail}</a>` })}
      </div>
      `,
    didOpen: () => {
      const customConfirmButton = document.querySelector('.swal2-confirm-custom') as HTMLDivElement;
      if (customConfirmButton !== null) {
        customConfirmButton.style.outlineColor = settingsStore.interfaceColor;
      }
    },
    ...getCommonParams(),
  };
  return swal.fire(filterValidParams({ ...defaultParams, ...params }));
};
