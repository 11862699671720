import { COOKIES, PRIVACY_POLICY } from '@/router/routes.names';
import type { RouteRecordRaw } from 'vue-router';

const documentsRoutes: RouteRecordRaw[] = [
  {
    path: 'documents',
    component: () => import('@/layouts/GameLayout.vue'),
    children: [
      {
        path: 'privacy-policy',
        name: PRIVACY_POLICY,
        component: () => import('@/views/PrivacyPolicy.vue'),
      },
      {
        path: 'cookies',
        name: COOKIES,
        component: () => import('@/views/PrivacyCookies.vue'),
      },
    ],
  },
];

export default documentsRoutes;
