import { env } from '@/env';
import { useSettingsStore } from '@/stores/settingsStore';

export function useSiteData(): void {
  const settingsStore = useSettingsStore();

  const setFavicon = (): void => {
    const faviconUrl = settingsStore.favicon ?? '';
    const link = document.querySelector('link[rel*="icon"]') as HTMLLinkElement;
    if (link) {
      link.href = faviconUrl;
    }
  };

  const setTitle = (): void => {
    const defaultTitle = env.appName;
    const pageTitle = settingsStore.metaTitle ?? settingsStore.gameTitle ?? defaultTitle;
    if (pageTitle) {
      document.title = pageTitle;
    }
  };

  setFavicon();
  setTitle();
}
