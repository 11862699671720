interface Props {
  body: string;
  imageUrl: string;
}

export default class Feedback {
  readonly body: string;
  readonly imageUrl: string;

  constructor({ body, imageUrl }: Props) {
    this.body = body;
    this.imageUrl = imageUrl;
  }
}
