type Props = {
  invalid?: boolean;
};

type Context = {
  disabled?: boolean;
};

export default {
  root: ({ context, props }: { context: Context; props: Props }) => ({
    class: [
      // Font
      'font-sans leading-none',

      // Spacing
      'm-0',
      'p-3',
      'w-full',

      // Shape
      'rounded-md',

      // Colors
      'text-neutral-700',
      'placeholder:text-neutral-400',
      'bg-white',
      'border',
      { 'border-neutral-200': !props.invalid },

      // Invalid State
      { 'border-red-500': props.invalid },

      // States
      {
        'hover:border-neutral-300': !context.disabled && !props.invalid,
        'focus:outline-none focus:outline-offset-0 input-focus': !context.disabled,
        'opacity-60 select-none pointer-events-none cursor-default': context.disabled,
      },

      // Misc
      'appearance-none',
      'transition-colors transition-shadow duration-200',
    ],
  }),
};
