import { immerable, produce } from 'immer';
import GameQuestionAnswer from '@/domain/entities/flow/GameQuestionAnswer';
import Badge from '@/domain/entities/flow/Badge';
import GameQuestionSessionAnswer from '@/domain/entities/flow/GameQuestionSessionAnswer';
import Feedback from '@/domain/entities/flow/Feedback';

interface Props {
  id: string;
  body: string | null;
  feedback: Feedback | null;
  badge: Badge | null;
  sessionAnswer: GameQuestionSessionAnswer | null;
  chosenAsBestAnswer: boolean;
  chosenAsWorstAnswer: boolean;
}

export default class BestWorstGameQuestionAnswer extends GameQuestionAnswer {
  [immerable] = true;

  readonly chosenAsBestAnswer: boolean;
  readonly chosenAsWorstAnswer: boolean;

  constructor({
    id,
    body,
    feedback,
    badge,
    sessionAnswer,
    chosenAsBestAnswer,
    chosenAsWorstAnswer,
  }: Props) {
    super({ id, body, feedback, badge, sessionAnswer });

    this.chosenAsBestAnswer = chosenAsBestAnswer;
    this.chosenAsWorstAnswer = chosenAsWorstAnswer;
  }

  chooseBestAnswer() {
    return produce(this, (draft) => {
      draft.chosenAsBestAnswer = true;
    });
  }

  unChooseBestAnswer() {
    return produce(this, (draft) => {
      draft.chosenAsBestAnswer = false;
    });
  }

  chooseWorstAnswer() {
    return produce(this, (draft) => {
      draft.chosenAsWorstAnswer = true;
    });
  }

  unChooseWorstAnswer() {
    return produce(this, (draft) => {
      draft.chosenAsWorstAnswer = false;
    });
  }

  unChoose() {
    return produce(this, (draft) => {
      draft.chosenAsBestAnswer = false;
      draft.chosenAsWorstAnswer = false;
    });
  }

  updateSessionAnswer(sessionAnswer: GameQuestionSessionAnswer) {
    return produce(this, (draft) => {
      draft.sessionAnswer = sessionAnswer;
    });
  }

  markBadgeAsCompleted(): GameQuestionAnswer {
    return produce(this, (draft) => {
      const currentBadge = draft.badge;
      if (currentBadge && 'markAsCompleted' in currentBadge) {
        draft.badge = currentBadge.markAsCompleted();
      }
    });
  }
}
