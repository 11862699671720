interface Props {
  severity?: 'info' | 'success' | 'secondary' | 'warn' | 'error' | 'contrast';
}

interface StyleObject {
  class: Array<string | Record<string, boolean>>;
}

interface TransitionObject {
  enterFromClass: string;
  enterActiveClass: string;
  leaveFromClass: string;
  leaveActiveClass: string;
  leaveToClass: string;
}

export default {
  root: ({ props }: { props: Props }): StyleObject => ({
    class: [
      // Spacing and Shape
      'rounded-md',
      'outline-solid outline-0 outline-l-[6px]',

      // Colors
      {
        'bg-red-100/70': props.severity === 'error',
      },
      {
        'outline-orange-500 dark:outline-orange-400': props.severity === 'warn',
        'outline-red-500': props.severity === 'error',
      },
      {
        'text-red-500': props.severity === 'error',
      },
    ],
  }),
  content: {
    class: [
      // Flexbox
      'flex items-center h-full',

      // Spacing
      'py-3 px-5 gap-2',
    ],
  },
  icon: {
    class: [
      // Sizing and Spacing
      'w-6 h-6',
      'text-lg leading-none shrink-0',
    ],
  },
  text: {
    class: [
      // Font and Text
      'text-base leading-none',
      'font-medium',
    ],
  },
  closeButton: {
    class: [
      // Flexbox
      'flex items-center justify-center',

      // Size
      'w-8 h-8',

      // Spacing and Misc
      'ml-auto relative',

      // Shape
      'rounded-full',

      // Colors
      'bg-transparent',

      // Transitions
      'transition duration-200 ease-in-out',

      // States
      'hover:bg-surface-0/50 dark:hover:bg-surface-0/10',

      // Misc
      'overflow-hidden',
    ],
  },
  transition: {
    enterFromClass: 'opacity-0',
    enterActiveClass: 'transition-opacity duration-300',
    leaveFromClass: 'max-h-40',
    leaveActiveClass: 'overflow-hidden transition-all duration-300 ease-in',
    leaveToClass: 'max-h-0 opacity-0 !m-0',
  } as TransitionObject,
};
