import { defineStore } from 'pinia';
import * as Sentry from '@sentry/vue';
import api from '@/common/axios/axios';
import { useSessionStore } from '@/stores/sessionStore';
import { useSiteData } from '@/common/useSiteData';
import { env } from '@/env';
import { setLocale } from '@vee-validate/i18n';
import { LocaleCodes } from '@/types/locale';
import type { LocaleCode } from '@/types/locale';
import type { Field } from '@/types/field';

interface GameState {
  data: {
    candidate: {
      data: {
        is_registered: boolean;
        personal_data_access: boolean;
      };
    } | null;
    client: {
      data: ClientData;
    };
    collections: {
      data: Collection[];
    };
    form: Form;
    game: {
      data: Game;
    };
    options: {
      client_options: {
        data: Option[];
      };
      game_options: {
        data: Option[];
      };
    };
    pages: Page[];
    recruitmentProcess: {
      data: RecruitmentProcess;
    };
  } | null;
  locale: LocaleCode;
}

interface Option {
  key: string;
  value: any;
}

interface Page {
  data: {
    additional_fields: AdditionalField[];
    body: string;
    side_image_url: string;
    mobile_side_image_url: string;
    type: string;
  };
}

export interface ClientData {
  facebook: string | null;
  id: string;
  linkedin: string | null;
  name: string;
  subdomain: string;
  website: string;
}

interface Collection {
  games: {
    data: Game[];
  };
  id: string;
  type: string;
}

interface Game {
  id: string;
  locale: string;
  meta_title: string | null;
  name: string;
  time_limit: number;
  top_bar_title: string;
  type: string;
}

interface Form {
  basic: {
    data: Field[];
  };
  additional: {
    data: Field[];
  };
}

interface AdditionalField {
  data: {
    name: string;
    value: string;
  };
}

interface RecruitmentProcess {
  id: string;
  locale: string;
  name: string;
  work_position: {
    data: {
      id: string;
      name: string;
    };
  };
  work_position_display_name: string;
}

const LOGO = 'LOGO';
const FAVICON = 'FAVICON';
const FAQ = 'FAQ';
const PRIMARY_COLOR = 'PRIMARY_COLOR';
const SUPPORT_EMAIL = 'SUPPORT_EMAIL';

export const useSettingsStore = defineStore('settingsStore', {
  state: (): GameState => ({
    data: null,
    locale: LocaleCodes.PL,
  }),
  actions: {
    async fetchData({
      recruitmentProcessId,
      sessionId = null,
    }: {
      recruitmentProcessId: string;
      sessionId: string | null;
    }): Promise<void> {
      const sessionStore = useSessionStore();
      const endpoint =
        sessionId === null
          ? `data/${recruitmentProcessId}`
          : `data/${recruitmentProcessId}/${sessionId}`;
      const response = await api.get(endpoint);
      const { data } = response.data;
      this.data = data;
      sessionStore.setGameTimeLimit(data.game.data.time_limit);
      this.setLocale(data.game.data.locale);
      useSiteData();
      Sentry.setTag('clientName', this.clientName);
      Sentry.setTag('recruitment_process_id', this.recruitmentProcessId);
    },
    async fetchPageTranslation(recruitmentProcessId: string, sessionId: string, pageType: string) {
      const response = await api.get(`page/${pageType}/${recruitmentProcessId}/${sessionId}`);
      const { data } = response.data;

      this.updatePageTranslation(data);
    },
    updatePageTranslation(pageData: any) {
      if (!this.data) return;
      const index = this.data.pages.findIndex((item) => item.data.type === pageData.type);
      if (index !== -1) {
        this.data.pages[index].data = { ...pageData };
      } else {
        this.data.pages.push({ data: { ...pageData } });
      }
    },
    setLocale(newLocale: LocaleCode): void {
      this.locale = newLocale;
      setLocale(newLocale.toLowerCase());
    },
  },
  getters: {
    hasGameData(state: GameState): boolean {
      return state.data !== null;
    },
    clientName(state: GameState): string | null {
      return state.data ? state.data.client.data.name : null;
    },
    appLocale(state: GameState): LocaleCode {
      return state.locale ? state.locale : 'PL';
    },
    gameId(state: GameState): string | null {
      return state.data ? state.data.game.data.id : null;
    },
    gameTitle(state: GameState): string {
      return state.data?.game.data.name ?? env.appName;
    },
    metaTitle(state: GameState): string | null {
      return state.data ? state.data.game.data.meta_title : null;
    },
    topBarTitle(state: GameState): string | null {
      return state.data ? state.data.game.data.top_bar_title : null;
    },
    recaptchaKey(): string {
      return env.recaptchaKey;
    },
    recruitmentProcessId(state: GameState): string | null {
      return state.data ? state.data.recruitmentProcess.data.id : null;
    },
    gameHasCollections(state: GameState): boolean {
      return state.data ? state.data.collections.data.length !== 0 : false;
    },
    gameCollections(state: GameState): Collection[] | null {
      return state.data ? state.data.collections.data : null;
    },
    registerFormBasicFields(state: GameState): Field[] | null {
      return state.data ? state.data.form.basic.data : null;
    },
    registerFormAdditionalFields(state: GameState): Field[] | null {
      return state.data ? state.data.form.additional.data : null;
    },
    personalDataAccess(store) {
      return store?.data?.candidate?.data?.personal_data_access ?? false;
    },
    isRegistered(store) {
      return store?.data?.candidate?.data?.is_registered ?? false;
    },
    logo(state: GameState): string | null {
      return (
        state.data?.options.client_options.data.find((option: Option) => option.key === LOGO)
          ?.value ?? null
      );
    },
    favicon(state: GameState): string | null {
      return (
        state.data?.options.client_options.data.find((option: Option) => option.key === FAVICON)
          ?.value ?? null
      );
    },
    supportEmail(state: GameState): string | null {
      return state.data
        ? state.data.options.client_options.data.find(
            (option: Option) => option.key === SUPPORT_EMAIL
          )?.value
        : null;
    },
    interfaceColor(state: GameState): string {
      return (
        state.data?.options.client_options.data.find(
          (option: Option) => option.key === PRIMARY_COLOR
        )?.value ?? '#FCAF3B'
      );
    },
    frequentlyAskedQuestions(state: GameState): [] {
      return state.data
        ? state.data.options.game_options.data.find((option: Option) => option.key === FAQ)?.value
        : [];
    },
    hasFrequentlyAskedQuestionsData(state: GameState): boolean | null {
      return state.data
        ? !!state.data.options.game_options.data.find((option: Option) => option.key === FAQ)
        : null;
    },
    translation:
      (state: GameState) =>
      (
        pageTitle: string
      ):
        | {
            additional_fields: AdditionalField[];
            body: string;
            side_image_url: string;
            mobile_side_image_url: string;
            type: string;
          }
        | null
        | undefined =>
        state.data
          ? state.data.pages.find((page: Page) => page.data.type === pageTitle)?.data
          : null,
    sideImage:
      (state: GameState) =>
      (pageTitle: string): string | null | undefined =>
        state.data
          ? state.data.pages.find((page: Page) => page.data.type === pageTitle)?.data.side_image_url
          : null,
    mobileSideImage:
      (state: GameState) =>
      (pageTitle: string): string | null | undefined =>
        state.data
          ? state.data.pages.find((page: Page) => page.data.type === pageTitle)?.data
              .mobile_side_image_url
          : null,
  },
});
