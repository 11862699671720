export const env = {
  appName: import.meta.env.VITE_APP_NAME,
  environmentName: import.meta.env.VITE_APP_ENVIRONMENT,
  apiUrl: import.meta.env.VITE_APP_API_URL,
  sentryKey: import.meta.env.VITE_APP_SENTRY_KEY,
  basicAuthUserName: import.meta.env.VITE_APP_BASIC_AUTH_USERNAME,
  basicAuthPassword: import.meta.env.VITE_APP_BASIC_AUTH_PASSWORD,
  recaptchaKey: import.meta.env.VITE_APP_RECAPTCHA_KEY,
  baseUrl: import.meta.env.BASE_URL,
  nodeEnv: import.meta.env.NODE_ENV,
};

export default {};
