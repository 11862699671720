interface Props {
  fluid?: boolean;
  disabled?: boolean;
  invalid?: boolean;
  showIcon?: boolean;
  iconDisplay?: string;
  inline?: boolean;
  touchUI?: boolean;
  modelValue?: any;
}

interface Context {
  date: {
    today: boolean;
  };
  selected?: boolean;
  disabled?: boolean;
}

export default {
  root: ({ props }: { props: Props }) => ({
    class: [
      {
        flex: props.fluid,
        'inline-flex': !props.fluid,
      },
      'max-w-full w-full',
      'relative',
      { 'opacity-60 select-none pointer-events-none cursor-default': props.disabled },
    ],
  }),
  pcInput: ({ props }: { props: Props }) => ({
    root: {
      class: [
        'flex-auto w-[1%]',
        'leading-none',
        'text-neutral-700',
        'placeholder:text-neutral-400',
        'bg-neutral-0',
        'border border-neutral-200',
        { 'border-red-500': props.invalid },
        'm-0 p-4',
        'appearance-none',
        { 'rounded-md': !props.showIcon || props.iconDisplay == 'input' },
        { 'rounded-l-md  flex-1 pr-9': props.showIcon && props.iconDisplay !== 'input' },
        { 'rounded-md flex-1 pr-9': props.showIcon && props.iconDisplay === 'input' },
        'transition-colors transition-shadow',
        'duration-200',
        { 'hover:border-neutral-300': !props.invalid },
        'focus:outline-none focus:outline-offset-0 input-focus',
      ],
    },
  }),
  dropdownIcon: {
    class: ['absolute top-[50%] -mt-2', 'text-white', 'right-[.75rem]'],
  },
  dropdown: {
    class: [
      'relative w-10',
      'items-center inline-flex text-center align-bottom justify-center',
      'rounded-r-md',
      'px-4 py-3 leading-none',
      'text-white',
      'primary-bg-color',
      'border primary-border-color',
      'focus:outline-none focus:outline-offset-0 input-focus',
      'hover-primary-bg-color hover-primary-border-color',
    ],
  },
  inputIconContainer: 'absolute cursor-pointer top-1/2 right-3 -mt-3',
  inputIcon: 'inline-block text-base',
  panel: ({ props }: { props: Props }) => ({
    class: [
      {
        absolute: !props.inline,
        'inline-block': props.inline,
      },
      { 'w-auto p-2 ': !props.inline },
      { 'min-w-[80vw] w-auto p-2 ': props.touchUI },
      { 'p-2 min-w-full': props.inline },
      'border rounded-lg',
      { 'shadow-md': !props.inline },
      'bg-white',
      'border-neutral-200',
      { 'overflow-x-auto': props.inline },
    ],
  }),
  header: {
    class: [
      'font-semibold',
      'flex items-center justify-between',
      'p-2',
      'm-0',
      'border-b',
      'rounded-t-md',
      'text-neutral-700',
      'bg-white',
      'border-neutral-200',
    ],
  },
  title: {
    class: ['leading-8', 'mx-auto my-0'],
  },
  selectMonth: {
    class: [
      'text-base leading-[normal]',
      'font-semibold',
      'text-neutral-700',
      'transition duration-200',
      'p-2',
      'm-0 mr-2',
      'hover:text-primary-500',
      'cursor-pointer',
    ],
  },
  selectYear: {
    class: [
      'text-base leading-[normal]',
      'font-semibold',
      'text-neutral-700',
      'transition duration-200',
      'p-2',
      'm-0',
      'hover:text-primary-500',
      'cursor-pointer',
    ],
  },
  table: {
    class: ['text-base leading-none', 'border-collapse', 'w-full', 'm-0 my-2'],
  },
  tableHeaderCell: {
    class: ['p-0 md:p-2'],
  },
  weekHeader: {
    class: ['leading-[normal]', 'text-neutral-600', 'opacity-60 cursor-default'],
  },
  weekNumber: {
    class: ['text-neutral-600', 'opacity-60 cursor-default'],
  },
  weekday: {
    class: ['text-neutral-500'],
  },
  dayCell: {
    class: ['p-0 md:p-2'],
  },
  weekLabelContainer: {
    class: [
      'flex items-center justify-center',
      'mx-auto',
      'w-10 h-10',
      'rounded-full',
      'border-transparent border',
      'opacity-60 cursor-default',
    ],
  },
  dayView: 'w-full',
  day: ({ context }: { context: Context }) => ({
    class: [
      'flex items-center justify-center',
      'mx-auto',
      'w-10 h-10',
      'rounded-full',
      'border-transparent border',
      {
        'text-neutral-900 bg-neutral-100 hover:bg-neutral-200': context.date.today,
        'text-neutral-600 bg-transparent':
          !context.selected && !context.disabled && !context.date.today,
        'text-neutral-100 bg-neutral-600 hover:text-neutral-100 hover:bg-neutral-600':
          context.selected && !context.disabled,
      },
      'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50',
      {
        'hover:bg-neutral-100': !context.selected && !context.disabled,
        'hover:bg-primary-highlight-hover': context.selected && !context.disabled,
      },
      {
        'opacity-60 cursor-default': context.disabled,
        'cursor-pointer': !context.disabled,
      },
    ],
  }),
  monthView: {
    class: ['my-2'],
  },
  month: ({ context }: { context: Context }) => ({
    class: [
      'inline-flex items-center justify-center',
      'w-1/3',
      'p-2',
      'rounded-md',
      {
        'text-neutral-600 bg-transparent': !context.selected && !context.disabled,
        'bg-highlight': context.selected && !context.disabled,
      },
      'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50',
      {
        'hover:bg-neutral-100': !context.selected && !context.disabled,
        'hover:bg-primary-highlight-hover': context.selected && !context.disabled,
      },
      'cursor-pointer',
    ],
  }),
  yearView: {
    class: ['my-2'],
  },
  year: ({ context }: { context: Context }) => ({
    class: [
      'inline-flex items-center justify-center',
      'w-1/3',
      'p-2',
      'rounded-md',
      {
        'text-neutral-600 bg-transparent': !context.selected && !context.disabled,
        'bg-highlight': context.selected && !context.disabled,
      },
      'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50',
      {
        'hover:bg-neutral-100': !context.selected && !context.disabled,
        'hover:bg-primary-highlight-hover': context.selected && !context.disabled,
      },
      'cursor-pointer',
    ],
  }),
  timePicker: {
    class: [
      'flex',
      'justify-center items-center',
      'border-t-1',
      'border-solid border-neutral-200',
      'p-2',
    ],
  },
  separatorContainer: {
    class: ['flex', 'items-center', 'flex-col', 'px-2'],
  },
  separator: {
    class: ['text-xl'],
  },
  hourPicker: {
    class: ['flex', 'items-center', 'flex-col', 'px-2'],
  },
  minutePicker: {
    class: ['flex', 'items-center', 'flex-col', 'px-2'],
  },
  secondPicker: {
    class: ['flex', 'items-center', 'flex-col', 'px-2'],
  },
  ampmPicker: {
    class: ['flex', 'items-center', 'flex-col', 'px-2'],
  },
  calendarContainer: 'flex',
  calendar: 'flex-auto border-l first:border-l-0 border-neutral-200',
  buttonbar: {
    class: ['flex justify-between items-center', 'py-3 px-0', 'border-t border-neutral-200'],
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass:
      'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
};
