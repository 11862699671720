import { defineStore } from 'pinia';

export interface Layout {
  isLoading: boolean;
  progress: number;
}

export const useLayoutStore = defineStore('layoutStore', {
  state: (): Layout => ({
    isLoading: false,
    progress: 0,
  }),
  actions: {
    startLoading(): void {
      this.isLoading = true;
    },
    stopLoading(): void {
      this.isLoading = false;
    },
    setProgress(value: number): number {
      this.progress = value;
      return value;
    },
    resetProgress(): void {
      this.progress = 0;
    },
  },
  getters: {
    getProgress: (state): number => state.progress,
  },
});
