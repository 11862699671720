import { immerable, produce } from 'immer';

interface VideoFiles {
  WEBM: string;
  MP4: string;
}

interface Videos {
  url: string;
  type: string;
}

interface Props {
  body: string;
  description: string;
  icon: string;
  iconUrl: string;
  imageUrl: string;
  video: VideoFiles;
  isCompleted: boolean;
}

export default class Badge {
  [immerable] = true;

  readonly body: string;
  readonly description: string;
  readonly icon: string;
  readonly iconUrl: string;
  readonly imageUrl: string;
  readonly video?: VideoFiles;
  readonly isCompleted: boolean;

  constructor({ body, description, icon, iconUrl, imageUrl, video, isCompleted }: Props) {
    this.body = body;
    this.description = description;
    this.icon = icon;
    this.iconUrl = iconUrl;
    this.imageUrl = imageUrl;
    this.video = video;
    this.isCompleted = isCompleted;
  }

  markAsCompleted() {
    return produce(this, (draft) => {
      draft.isCompleted = true;
    });
  }

  get videos(): Videos[] {
    if (!this.isVideoBadge) return [];

    return [
      {
        url: this.video!.WEBM,
        type: 'video/webm',
      },
      {
        url: this.video!.MP4,
        type: 'video/mp4',
      },
    ];
  }

  get isVideoBadge(): boolean {
    return !!this.video;
  }
}
