import Axios from 'axios';
import { env } from '@/env';
import { errorResponseHandler } from '@/common/axios/errorResponseHandler';
import type { AxiosInstance } from 'axios';

const instance: AxiosInstance = Axios.create({
  baseURL: env.apiUrl,
  auth:
    env.basicAuthUserName && env.basicAuthPassword
      ? {
          username: env.basicAuthUserName,
          password: env.basicAuthPassword,
        }
      : undefined,
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => errorResponseHandler(error)
);

export default instance;
