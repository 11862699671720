<template>
  <transition name="fade-down-animation" mode="out-in">
    <div v-if="!isCookieAccepted && hasRecruitmentProcessIdParam" class="cookies-bar">
      <span>{{ $t('title') }}</span>
      <router-link class="cookies-bar__link" :to="cookiesRoute">
        {{ $t('link') }}
      </router-link>
      <span>🍪!</span>
      <BaseButton class="cookies-bar__button" size="small" @click="acceptCookies">
        {{ $t('button') }}
      </BaseButton>
    </div>
  </transition>
</template>
<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useCookieStore } from '@/stores/cookieStore';
import BaseButton from '@/components/Form/BaseButton.vue';
import { COOKIES } from '@/router/routes.names';
import type { ComputedRef } from 'vue';

const route = useRoute();
const cookieStore = useCookieStore();

const cookiesRoute: { name: string } = { name: COOKIES };
const hasRecruitmentProcessIdParam: ComputedRef<boolean> = computed(
  (): boolean => !!route.params.recruitmentProcessId
);
const isCookieAccepted: ComputedRef<boolean> = computed(
  (): boolean => cookieStore.isCookieAccepted
);

onMounted(() => {
  cookieStore.getCookieStatus();
});

const acceptCookies = (): void => {
  cookieStore.acceptCookies();
};
</script>
<style lang="scss" scoped>
.cookies-bar {
  @apply text-base fixed left-0 right-0 bottom-0 bg-white py-3 px-2.5 text-center;
  line-height: 42px;
  z-index: 9999;
  box-shadow:
    0px -41px 80px rgba(0, 0, 0, 0.05),
    0px -17.1288px 33.4221px rgba(0, 0, 0, 0.0359427),
    0px -9.15789px 17.869px rgba(0, 0, 0, 0.0298054),
    0px -5.13384px 10.0172px rgba(0, 0, 0, 0.025),
    0px -2.72654px 5.3201px rgba(0, 0, 0, 0.0201946),
    0px -1.13458px 2.2138px rgba(0, 0, 0, 0.0140573);
  &__link {
    @apply font-medium mx-1;
    color: var(--primary-color);
  }
  &__button {
    @apply mx-5;
  }
}
</style>
