import Stage from '@/domain/entities/flow/Stage';

interface Props {
  id: string;
  type: string;
  order: number;
  completedAt: Date | null;
  title: string;
  imageUrl: string;
  continueBtn: string;
}

export default class GameImage extends Stage {
  readonly title: string;
  readonly imageUrl: string;
  readonly continueBtn: string;

  constructor({ id, type, order, completedAt, title, imageUrl, continueBtn }: Props) {
    super({ id, type, order, completedAt });

    this.title = title;
    this.imageUrl = imageUrl;
    this.continueBtn = continueBtn;
  }
}
