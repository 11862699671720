interface Context {
  right?: boolean;
  left?: boolean;
  top?: boolean;
  bottom?: boolean;
}

export default {
  root: ({ context }: { context: Context }) => ({
    class: [
      // Position and Shadows
      'absolute',
      'p-fadein',
      // Spacing
      {
        'py-0 px-1':
          context?.right ||
          context?.left ||
          (!context?.right && !context?.left && !context?.top && !context?.bottom),
        'py-1 px-0': context?.top || context?.bottom,
      },
    ],
  }),
  arrow: ({ context }: { context: Context }) => ({
    class: [
      // Position
      'absolute',

      // Size
      'w-0',
      'h-0',

      // Shape
      'border-transparent',
      'border-solid',
      {
        'border-y-[0.25rem] border-r-[0.25rem] border-l-0 border-r-neutral-600':
          context?.right ||
          (!context?.right && !context?.left && !context?.top && !context?.bottom),
        'border-y-[0.25rem] border-l-[0.25rem] border-r-0 border-l-neutral-600': context?.left,
        'border-x-[0.25rem] border-t-[0.25rem] border-b-0 border-t-neutral-600': context?.top,
        'border-x-[0.25rem] border-b-[0.25rem] border-t-0 border-b-neutral-600': context?.bottom,
      },

      // Spacing
      {
        '-mt-1 ':
          context?.right ||
          (!context?.right && !context?.left && !context?.top && !context?.bottom),
        '-mt-1': context?.left,
        '-ml-1': context?.top || context?.bottom,
      },
    ],
  }),
  text: {
    class: [
      'max-w-[250px]',
      'p-3',
      'bg-neutral-800',
      'opacity-75',
      'text-center',
      'text-white',
      'text-sm',
      'leading-none',
      'rounded-md',
      'whitespace-pre-line',
      'break-words',
    ],
  },
};
