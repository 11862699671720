export function useConvertColorToRgb(): {
  convertColorToRgb: (hex: string, alpha?: number | false) => string;
} {
  const convertColorToRgb = (hex: string, alpha: number | false = false): string => {
    const [r, g, b] = hex.match(/\w\w/g)?.map((x) => parseInt(x, 16)) || [0, 0, 0];

    if (alpha !== false) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgb(${r}, ${g}, ${b})`;
  };

  return { convertColorToRgb };
}
