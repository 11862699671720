interface Props {
  showButtons?: boolean;
  invalid?: boolean;
  disabled?: boolean;
  modelValue?: any;
  showClear?: boolean;
}

interface State {
  focused: boolean;
}

interface Parent {
  instance: {
    $name: string;
  };
}

interface Context {
  focused: boolean;
  selected: boolean;
  disabled: boolean;
}

export default {
  root: ({ props, state, parent }: { props: Props; state: State; parent: Parent }) => ({
    class: [
      'inline-flex',
      'relative',
      'w-full',
      { 'rounded-md': parent.instance.$name !== 'InputGroup' },
      {
        'first:rounded-l-md rounded-none last:rounded-r-md': parent.instance.$name == 'InputGroup',
      },
      { 'border-0 border-y border-l last:border-r': parent.instance.$name == 'InputGroup' },
      { 'first:ml-0 ml-[-1px]': parent.instance.$name == 'InputGroup' && !props.showButtons },
      'bg-white',
      'border border-neutral-200',
      { 'border-neutral-200': !props.invalid },
      { 'border-red-500': props.invalid },
      'transition-all',
      'duration-200',
      { 'hover:border-neutral-300': !props.invalid },
      { 'outline-none outline-offset-0 primary-ring-color': state.focused },
      'cursor-pointer',
      'select-none',
      {
        'opacity-60': props.disabled,
        'pointer-events-none': props.disabled,
        'cursor-default': props.disabled,
      },
    ],
  }),
  label: ({ props, parent }: { props: Props; parent: Parent }) => ({
    class: [
      'leading-[normal]',
      'block',
      'flex-auto',
      'bg-transparent',
      'border-0',
      {
        'text-neutral-700': props.modelValue?.length,
        'text-neutral-400': !props.modelValue?.length,
      },
      'placeholder:text-neutral-400',
      'w-[1%]',
      'p-4',
      { 'pr-7': props.showClear },
      'rounded-none',
      'transition',
      'duration-200',
      'focus:outline-none focus:shadow-none',
      { filled: parent.instance?.$name == 'FloatLabel' && props.modelValue !== null },
      'relative',
      'cursor-pointer',
      'overflow-hidden overflow-ellipsis',
      'whitespace-nowrap',
      'appearance-none',
    ],
  }),
  dropdown: {
    class: [
      'flex items-center justify-center',
      'shrink-0',
      'bg-transparent',
      'text-neutral-500',
      'w-12',
      'rounded-tr-md',
      'rounded-br-md',
    ],
  },
  overlay: {
    class: [
      'absolute top-0 left-0',
      'border-0',
      'rounded-md',
      'shadow-md',
      'bg-white',
      'text-neutral-800',
    ],
  },
  listContainer: {
    class: ['max-h-[200px]', 'overflow-auto'],
  },
  list: {
    class: 'py-3 list-none m-0',
  },
  option: ({ context }: { context: Context }) => ({
    class: [
      'font-normal',
      'leading-none',
      'relative',
      'flex items-center',
      'border-0',
      'rounded-none',
      'm-0',
      'py-3 px-5',
      { 'text-neutral-700': !context.focused && !context.selected && !context.disabled },
      { 'text-neutral-600': !context.focused && !context.selected && context.disabled },
      { 'bg-neutral-200 text-neutral-700': context.focused && !context.selected },
      { 'bg-neutral-100 text-neutral-700': context.focused && context.selected },
      { 'bg-neutral-50 text-neutral-700': !context.focused && context.selected },
      { 'hover:bg-neutral-100': !context.focused && !context.selected },
      { 'hover:text-neutral-700 hover:bg-neutral-100': context.focused && !context.selected },
      'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-inset focus-visible:ring-neutral-400/50',
      'transition-shadow',
      'duration-200',
      { 'pointer-events-none cursor-default': context.disabled },
      { 'cursor-pointer': !context.disabled },
      'overflow-hidden',
      'whitespace-nowrap',
    ],
  }),
  optionGroup: {
    class: ['font-bold', 'm-0', 'py-3 px-5', 'text-neutral-800', 'bg-white', 'cursor-auto'],
  },
  optionCheckIcon: 'relative -ms-1.5 me-1.5 text-neutral-700 w-4 h-4',
  optionBlankIcon: 'w-4 h-4',
  emptyMessage: {
    class: ['leading-none', 'py-3 px-5', 'text-neutral-700', 'bg-transparent'],
  },
  header: {
    class: [
      'py-3 px-5',
      'm-0',
      'border-b',
      'rounded-tl-md',
      'rounded-tr-md',
      'text-neutral-700',
      'bg-neutral-100',
      'border-neutral-300',
    ],
  },
  clearIcon: {
    class: ['text-neutral-500', 'absolute', 'top-1/2', 'right-12', '-mt-2'],
  },
  loadingIcon: {
    class: 'text-neutral-400 animate-spin',
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass:
      'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
};
