interface Props {
  showButtons?: boolean;
  buttonLayout?: 'vertical' | 'horizontal' | 'stacked';
  fluid?: boolean;
  invalid?: boolean;
}

interface Parent {
  instance: {
    $name: string;
    $parentInstance?: {
      $name: string;
    };
  };
  props: Props;
  state?: {
    d_modelValue?: any;
  };
}

interface Context {
  disabled?: boolean;
}

export default {
  root: ({ props, parent }: { props: Props; parent: Parent }) => ({
    class: [
      'inline-flex w-full',
      'relative',
      { 'flex-col': props.showButtons && props.buttonLayout === 'vertical' },
      { 'flex-1 w-[1%]': parent.instance.$name === 'InputGroup' },
      { 'w-full': props.fluid },
      {
        'first:rounded-l-md rounded-none last:rounded-r-md':
          parent.instance.$name === 'InputGroup' && !props.showButtons,
      },
      {
        'border-0 border-y border-l last:border-r border-neutral-300':
          parent.instance.$name === 'InputGroup' && !props.showButtons,
      },
      { 'first:ml-0 -ml-px': parent.instance.$name === 'InputGroup' && !props.showButtons },
      { '!w-16': props.showButtons && props.buttonLayout === 'vertical' },
    ],
  }),
  pcInput: {
    root: ({ parent, context }: { parent: Parent; context: Context }) => ({
      class: [
        'flex-auto',
        { 'w-[1%]': parent.props.fluid },
        { 'text-center': parent.props.showButtons && parent.props.buttonLayout === 'vertical' },
        'p-3.5',
        'm-0',
        'rounded-md',
        {
          'rounded-l-none rounded-r-none':
            parent.props.showButtons && parent.props.buttonLayout === 'horizontal',
        },
        { 'rounded-none': parent.props.showButtons && parent.props.buttonLayout === 'vertical' },
        {
          'border-0':
            parent.instance.$parentInstance?.$name === 'InputGroup' && !parent.props.showButtons,
        },
        'text-neutral-700',
        'placeholder:text-neutral-400',
        { 'bg-white': !context.disabled },
        'border',
        { 'border-neutral-200': !parent.props.invalid },
        'invalid:focus:ring-red-200',
        'invalid:hover:border-red-500',
        { 'border-red-500': parent.props.invalid },
        { 'hover:border-neutral-300': !parent.props.invalid },
        'focus:outline-none focus:outline-offset-0 input-focus focus:z-10',
        { 'opacity-60 select-none pointer-events-none cursor-default': context.disabled },
        {
          filled:
            parent.instance?.$parentInstance?.$name === 'FloatLabel' &&
            parent.state?.d_modelValue !== null,
        },
        {
          'order-2':
            parent.props.buttonLayout === 'horizontal' || parent.props.buttonLayout === 'vertical',
        },

        // Misc
        'appearance-none',
        'transition-colors transition-shadow duration-200',
      ],
    }),
  },
  buttonGroup: ({ props }: { props: Props }) => ({
    class: [
      'absolute',
      'flex',
      'flex-col',
      'top-px right-px',
      { 'h-[calc(100%-2px)]': props.showButtons && props.buttonLayout === 'stacked' },
    ],
  }),
  incrementButton: ({ props }: { props: Props }) => ({
    class: [
      { 'flex flex-initial shrink-0': props.showButtons && props.buttonLayout === 'horizontal' },
      { 'flex flex-auto': props.showButtons && props.buttonLayout === 'stacked' },
      'items-center',
      'justify-center',
      'text-center align-bottom',
      'relative',
      { 'order-3': props.showButtons && props.buttonLayout === 'horizontal' },
      { 'order-1': props.showButtons && props.buttonLayout === 'vertical' },
      'text-white',
      'primary-bg-color',
      'border primary-border-color',
      'w-[3rem]',
      { 'px-4 py-3': props.showButtons && props.buttonLayout !== 'stacked' },
      { 'p-0': props.showButtons && props.buttonLayout === 'stacked' },
      { 'w-full': props.showButtons && props.buttonLayout === 'vertical' },
      'rounded-md',
      {
        'rounded-tl-none rounded-br-none rounded-bl-none':
          props.showButtons && props.buttonLayout === 'stacked',
      },
      {
        'rounded-bl-none rounded-tl-none': props.showButtons && props.buttonLayout === 'horizontal',
      },
      { 'rounded-bl-none rounded-br-none': props.showButtons && props.buttonLayout === 'vertical' },
      'focus:outline-none focus:outline-offset-0 focus:ring',
      'hover:bg-primary-emphasis hover:border-primary-emphasis',
      'cursor-pointer overflow-hidden select-none',
    ],
  }),
  incrementIcon: 'inline-block w-4 h-4',
  decrementButton: ({ props }: { props: Props }) => ({
    class: [
      { 'flex flex-initial shrink-0': props.showButtons && props.buttonLayout === 'horizontal' },
      { 'flex flex-auto': props.showButtons && props.buttonLayout === 'stacked' },
      'items-center',
      'justify-center',
      'text-center align-bottom',
      'relative',
      { 'order-1': props.showButtons && props.buttonLayout === 'horizontal' },
      { 'order-3': props.showButtons && props.buttonLayout === 'vertical' },
      'text-white',
      'primary-bg-color',
      'border primary-border-color',
      'w-[3rem]',
      { 'px-4 py-3': props.showButtons && props.buttonLayout !== 'stacked' },
      { 'p-0': props.showButtons && props.buttonLayout === 'stacked' },
      { 'w-full': props.showButtons && props.buttonLayout === 'vertical' },
      'rounded-md',
      {
        'rounded-tr-none rounded-tl-none rounded-bl-none':
          props.showButtons && props.buttonLayout === 'stacked',
      },
      {
        'rounded-tr-none rounded-br-none': props.showButtons && props.buttonLayout === 'horizontal',
      },
      { 'rounded-tr-none rounded-tl-none': props.showButtons && props.buttonLayout === 'vertical' },
      'focus:outline-none focus:outline-offset-0 focus:ring',
      'hover:bg-primary-emphasis hover:border-primary-emphasis',
      'cursor-pointer overflow-hidden select-none',
    ],
  }),
  decrementIcon: 'inline-block w-4 h-4',
};
