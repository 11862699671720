import { immerable } from 'immer';
import Badge from '@/domain/entities/flow/Badge';
import Feedback from '@/domain/entities/flow/Feedback';
import GameQuestionSessionAnswer from '@/domain/entities/flow/GameQuestionSessionAnswer';

interface Props {
  id: string;
  body: string | null;
  feedback: Feedback | null;
  badge: Badge | null;
  sessionAnswer: GameQuestionSessionAnswer | null;
}

export default abstract class GameQuestionAnswer {
  [immerable] = true;

  readonly id: string;
  readonly badge: Badge | null;
  readonly sessionAnswer: GameQuestionSessionAnswer | null;
  readonly body: string | null;
  readonly feedback: Feedback | null;

  constructor({ id, body, feedback, badge, sessionAnswer }: Props) {
    this.id = id;
    this.body = body;
    this.feedback = feedback;
    this.badge = badge;
    this.sessionAnswer = sessionAnswer;
  }

  abstract updateSessionAnswer(sessionAnswers: GameQuestionSessionAnswer): void;
  abstract markBadgeAsCompleted(sessionAnswers: GameQuestionSessionAnswer): void;
}
