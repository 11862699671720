import { useSessionStore } from '@/stores/sessionStore';
import { useSettingsStore } from '@/stores/settingsStore';
import { CHOOSE_LANG, LOGIN, REGISTER } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED } from '@/common/axios/httpStatusCodes';
import type { RouteRecordRaw, NavigationGuard } from 'vue-router';

interface RouteParams {
  sessionId?: string;
  recruitmentProcessId?: string;
}

const checkRegisterStatus: NavigationGuard = async (to, from, next): Promise<void> => {
  const settingsStore = useSettingsStore();
  const params = to.params as RouteParams;

  try {
    if (params.sessionId) {
      const isRegistered = settingsStore.isRegistered;
      if (!isRegistered && to.name === LOGIN) {
        next({
          name: REGISTER,
          params: {
            recruitmentProcessId: params.recruitmentProcessId,
            sessionId: params.sessionId,
          },
        });
        return;
      }
      if (isRegistered && to.name === REGISTER) {
        next({
          name: LOGIN,
          params: {
            recruitmentProcessId: params.recruitmentProcessId,
            sessionId: params.sessionId,
          },
        });
        return;
      }
    }
    if (!params.sessionId && to.name === LOGIN) {
      next({ name: REGISTER, params: { recruitmentProcessId: params.recruitmentProcessId } });
      return;
    }

    next();
  } catch (e: any) {
    const statusCode = e.response?.status;

    if (statusCode === HTTP_UNAUTHORIZED) {
      next();
      return;
    }
    throw new Error(e.message || 'An error occurred during navigation.');
  }
};

const authRoutes: RouteRecordRaw[] = [
  {
    path: 'choose-language',
    name: CHOOSE_LANG,
    component: () => import('@/views/ChooseLang.vue'),
    beforeEnter: async (to, from, next) => {
      const sessionStore = useSessionStore();
      const settingsStore = useSettingsStore();
      try {
        const gameHasCollections = settingsStore.gameHasCollections;
        const sessionLocale = sessionStore.sessionLocale;
        if (!gameHasCollections || (sessionLocale && gameHasCollections)) {
          next({
            name: REGISTER,
            params: {
              recruitmentProcessId: to.params.recruitmentProcessId,
              sessionId: to.params.sessionId,
            },
          });
          return;
        }
        next();
      } catch (e: any) {
        const statusCode = e.response?.status;

        if (statusCode === HTTP_UNAUTHORIZED) {
          next();
          return;
        }
        throw new Error(e.message || 'An error occurred during navigation.');
      }
    },
  },
  {
    path: 'login',
    name: LOGIN,
    component: () => import('@/views/Login.vue'),
    beforeEnter: checkRegisterStatus,
  },
  {
    path: 'register',
    name: REGISTER,
    component: () => import('@/views/Register.vue'),
    beforeEnter: checkRegisterStatus,
  },
];

export default authRoutes;
