import Badge from '@/domain/entities/flow/Badge';
import GameQuestionSessionAnswer from '@/domain/entities/flow/GameQuestionSessionAnswer';
import GameRecordSessionRecord from '@/domain/entities/flow/GameRecordSessionRecord';
import Feedback from '@/domain/entities/flow/Feedback';
import ClosedGameQuestion from '@/domain/entities/flow/ClosedGameQuestion';
import ClosedGameQuestionAnswer from '@/domain/entities/flow/ClosedGameQuestionAnswer';
import BestWorstGameQuestion from '@/domain/entities/flow/BestWorstGameQuestion';
import BestWorstGameQuestionAnswer from '@/domain/entities/flow/BestWorstGameQuestionAnswer';
import GameMessage from '@/domain/entities/flow/GameMessage';
import GameVideo from '@/domain/entities/flow/GameVideo';
import GameRecord from '@/domain/entities/flow/GameRecord';
import GameImage from '@/domain/entities/flow/GameImage';
import Flow from '@/domain/aggregates/Flow';
import type {
  BadgeData,
  StageData,
  QuestionBasicData,
  SessionRecordData,
  FeedbackData,
  SessionAnswerData,
  Answer,
} from '@/types/factory';
import { BEST_TYPE_NAME, WORST_TYPE_NAME } from '@/names/gameQuestion.names';

const badgeFactory = (badge: BadgeData, isCompleted: boolean): Badge =>
  new Badge({
    body: badge.data.body,
    description: badge.data.description,
    icon: badge.data.icon,
    iconUrl: badge.data.icon_url,
    imageUrl: badge.data.image_url,
    video: badge.data.video,
    isCompleted,
  });

const feedbackFactory = (feedback: FeedbackData): Feedback =>
  new Feedback({
    body: feedback.data.body,
    imageUrl: feedback.data.image_url,
  });

const stageBasicData = (stage: StageData) => ({
  id: stage.id,
  type: stage.stageable.data.type,
  order: stage.order,
  completedAt: stage.session_stage?.data.completed_at
    ? new Date(stage.session_stage.data.completed_at)
    : null,
  badge: stage.badge ? badgeFactory(stage.badge, !!stage.session_stage) : null,
  sideImageUrl: stage.side_image_url,
  mobileSideImageUrl: stage.mobile_side_image_url,
});

const sessionAnswerFactory = ({
  data: { answer, created_at: createdAt },
}: SessionAnswerData): GameQuestionSessionAnswer =>
  new GameQuestionSessionAnswer({
    createAt: new Date(createdAt),
    answer,
  });

const sessionRecordFactory = ({
  data: { created_at: createdAt },
}: SessionRecordData): GameRecordSessionRecord =>
  new GameRecordSessionRecord({
    createAt: new Date(createdAt),
  });

const closedQuestionAnswersFactory = (answers?: Answer[]): ClosedGameQuestionAnswer[] =>
  (answers || [])
    .sort(() => Math.random() - 0.5)
    .map(
      (answer) =>
        new ClosedGameQuestionAnswer({
          id: answer.id,
          body: answer.body ? answer.body : null,
          feedback: answer.feedback ? feedbackFactory(answer.feedback) : null,
          badge: answer.badge ? badgeFactory(answer.badge, !!answer.session_answer) : null,
          sessionAnswer: answer.session_answer ? sessionAnswerFactory(answer.session_answer) : null,
          chosen: !!answer.session_answer,
        })
    );

const bestWorstQuestionAnswersFactory = (answers?: Answer[]): BestWorstGameQuestionAnswer[] =>
  (answers || [])
    .sort(() => Math.random() - 0.5)
    .map(
      (answer) =>
        new BestWorstGameQuestionAnswer({
          id: answer.id,
          body: answer.body ? answer.body : null,
          feedback: answer.feedback ? feedbackFactory(answer.feedback) : null,
          badge: answer.badge
            ? badgeFactory(answer.badge, answer.session_answer?.data.answer === BEST_TYPE_NAME)
            : null,
          sessionAnswer: answer.session_answer ? sessionAnswerFactory(answer.session_answer) : null,
          chosenAsBestAnswer: answer.session_answer?.data.answer === BEST_TYPE_NAME,
          chosenAsWorstAnswer: answer.session_answer?.data.answer === WORST_TYPE_NAME,
        })
    );

const questionBasicData = (stage: StageData): QuestionBasicData => ({
  ...stageBasicData(stage),
  body: stage.stageable.data.body,
  questionId: stage.stageable.data.id,
  instruction: stage.stageable.data.instruction,
  imageUrl: stage.stageable.data.image_url,
  header: stage.stageable.data.header,
});

const FLOW_FACTORIES: Record<
  string,
  (
    stage: StageData
  ) => ClosedGameQuestion | BestWorstGameQuestion | GameMessage | GameVideo | GameImage | GameRecord
> = {
  gameQuestion: (stage: StageData) => {
    const gameQuestionFactories: any = {
      CLOSED: () =>
        new ClosedGameQuestion({
          ...questionBasicData(stage),
          answers: closedQuestionAnswersFactory(stage.stageable.data.answers?.data),
        }),
      BEST_WORST: () =>
        new BestWorstGameQuestion({
          ...questionBasicData(stage),
          answers: bestWorstQuestionAnswersFactory(stage.stageable.data.answers?.data),
        }),
    };

    if (!(stage.stageable.data.type in gameQuestionFactories)) {
      throw new Error(
        `Missing factory definition for question game type ${stage.stageable.data.type}`
      );
    }

    return gameQuestionFactories[stage.stageable.data.type]();
  },
  gameMessage: (stage: StageData) =>
    new GameMessage({
      ...stageBasicData(stage),
      title: stage.stageable.data.title,
      body: stage.stageable.data.body,
      feedback: stage.stageable.data.feedback
        ? feedbackFactory(stage.stageable.data.feedback)
        : null,
    }),
  gameVideo: (stage: StageData) =>
    new GameVideo({
      ...stageBasicData(stage),
      title: stage.stageable.data.title,
      files: stage.stageable.data.files,
    }),
  gameImage: (stage: StageData) =>
    new GameImage({
      ...stageBasicData(stage),
      title: stage.stageable.data.title,
      imageUrl: stage.stageable.data.image_url,
      continueBtn: stage.stageable.data.continue_btn,
    }),
  gameRecord: (stage: StageData) =>
    new GameRecord({
      ...stageBasicData(stage),
      title: stage.stageable.data.title,
      required: stage.stageable.data.required,
      timeLimit: stage.stageable.data.time_limit,
      body: stage.stageable.data.body,
      recordId: stage.stageable.data.id,
      instruction: stage.stageable.data.instruction,
      header: stage.stageable.data.header,
      feedback: stage.stageable.data.feedback
        ? feedbackFactory(stage.stageable.data.feedback)
        : null,
      sessionRecord: stage.stageable.data.session_record
        ? sessionRecordFactory(stage.stageable.data.session_record)
        : null,
    }),
};

export default function createFlow(flowData: StageData[]): Flow {
  const stages = flowData.map((stage): any => {
    if (!(stage.stageable_type in FLOW_FACTORIES)) {
      throw new Error(`Missing factory definition for ${stage.stageable_type} stage type`);
    }

    return FLOW_FACTORIES[stage.stageable_type](stage);
  });

  return new Flow(stages);
}
