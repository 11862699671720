<template>
  <div :style="colorProps">
    <Loader v-show="isLoading" :is-component-loader="true" />
    <router-view />
    <CookieBar v-if="settingsStore.hasGameData" />
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, watch } from 'vue';
import { useSettingsStore } from '@/stores/settingsStore';
import { useLayoutStore } from '@/stores/layoutStore';
import { useConvertColorToRgb } from '@/common/useConvertColorToRgb';
import { useLocaleConfig } from '@/common/useLocaleConfig';
import CookieBar from '@/components/Document/CookieBar.vue';
import Loader from '@/components/Loader/Loader.vue';
import type { ComputedRef } from 'vue';

const { convertColorToRgb } = useConvertColorToRgb();
const { setPrimeVueLocaleConfig } = useLocaleConfig();

const settingsStore = useSettingsStore();
const layoutStore = useLayoutStore();

const isLoading: ComputedRef<boolean> = computed((): boolean => layoutStore.isLoading);
const colorProps: ComputedRef<Record<string, string>> = computed(
  (): Record<string, string> => ({
    '--primary-color': convertColorToRgb(settingsStore.interfaceColor),
    '--hover-primary-color': convertColorToRgb(settingsStore.interfaceColor, 0.8),
    '--light-primary-color': convertColorToRgb(settingsStore.interfaceColor, 0.4),
    '--lighter-primary-color': convertColorToRgb(settingsStore.interfaceColor, 0.2),
    '--lightest-primary-color': convertColorToRgb(settingsStore.interfaceColor, 0.05),
  })
);

watch(
  () => settingsStore.locale,
  (value) => {
    setPrimeVueLocaleConfig(value.toLowerCase());
  }
);

onMounted(async () => {
  await setPrimeVueLocaleConfig(settingsStore.locale.toLowerCase());
});
</script>
