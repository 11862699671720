import { AxiosError } from 'axios';
import router from '@/router';
import { useSessionStore } from '@/stores/sessionStore';
import { warningAlert } from '@/common/useAlert';
import {
  HTTP_BAD_REQUEST,
  HTTP_NOT_FOUND,
  HTTP_UNPROCESSABLE_ENTITY,
  HTTP_UNAUTHORIZED,
} from '@/common/axios/httpStatusCodes';
import { NOT_FOUND, LOGIN } from '@/router/routes.names';
import * as Sentry from '@sentry/vue';

interface CurrentRouteState {
  params: {
    recruitmentProcessId?: string;
    sessionId?: string;
  };
}

const extractErrorDetails = (error: any) => {
  const errorData = error.response?.data;

  if (errorData && typeof errorData === 'object' && 'meta' in errorData) {
    delete errorData.meta;
  }

  return errorData;
};

export const errorResponseHandler = async (error: AxiosError): Promise<void> => {
  const { status } = error.response || {};

  if (status === HTTP_NOT_FOUND) {
    await router.push({
      name: NOT_FOUND,
    });

    return Promise.reject(error);
  }
  if (status === HTTP_BAD_REQUEST || status === HTTP_UNPROCESSABLE_ENTITY)
    return Promise.reject(error);
  if (status === HTTP_UNAUTHORIZED) {
    const sessionStore = useSessionStore();
    const currentRoute = sessionStore.currentRoute as CurrentRouteState;

    await router.push({
      name: LOGIN,
      params: {
        recruitmentProcessId: currentRoute.params.recruitmentProcessId,
        sessionId: currentRoute.params.sessionId,
      },
    });

    return Promise.reject(error);
  }
  warningAlert();
  Sentry.captureException(JSON.stringify(extractErrorDetails(error)));

  return Promise.reject(error);
};
