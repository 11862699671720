interface Props {
  createAt: Date;
  answer: string;
}

export default class GameQuestionSessionAnswer {
  readonly createAt: Date;
  readonly answer?: string;

  constructor({ createAt, answer }: Props) {
    this.createAt = createAt;
    this.answer = answer;
  }
}
