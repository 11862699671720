export const BADGES = 'BADGES';
export const CHOOSE_LANG = 'CHOOSE_LANG';
export const LOGIN = 'LOGIN';
export const REGISTER = 'REGISTER';
export const WELCOME = 'WELCOME';
export const FINISH = 'FINISH';
export const GAME = 'GAME';
export const END_OF_TIME = 'END_OF_TIME';
export const NOT_FOUND = 'NOT_FOUND';
export const FAQ = 'FAQ';
export const PRIVACY_POLICY = 'PRIVACY_POLICY';
export const COOKIES = 'COOKIES';
export const GAME_LAYOUT = 'GAME_LAYOUT';
export const RECORD_TEST = 'RECORD_TEST';
