interface StyleObject {
  class: string | Array<string | Record<string, boolean>>;
}

export default {
  root: {
    class: [
      // Flexbox
      'inline-flex items-center',

      // Spacing
      'px-2 gap-2 m-0.5',

      // Shape
      'rounded-[16px]',

      // Colors
      'text-neutral-700',
      'bg-neutral-200',
      'text-sm',
    ],
  } as StyleObject,
  label: {
    class: 'leading-6 my-1.5 mx-0',
  } as StyleObject,
  icon: {
    class: 'leading-6 mr-2',
  } as StyleObject,
  image: {
    class: ['w-9 h-9 -ml-3 mr-2', 'rounded-full'],
  } as StyleObject,
  removeIcon: {
    class: [
      // Shape
      'rounded-md leading-6',
      'focus:outline-none focus:outline-offset-0 input-focus',

      // Size
      'w-4 h-4',

      // Transition
      'transition duration-200 ease-in-out',

      // Misc
      'cursor-pointer',
    ],
  } as StyleObject,
};
