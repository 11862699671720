import Stage from '@/domain/entities/flow/Stage';
import Badge from '@/domain/entities/flow/Badge';
import Feedback from '@/domain/entities/flow/Feedback';
import GameRecordSessionRecord from '@/domain/entities/flow/GameRecordSessionRecord';

interface Props {
  id: string;
  type: string;
  order: number;
  title: string;
  body: string;
  completedAt: Date | null;
  recordId: string;
  badge: Badge | null;
  instruction: string;
  sideImageUrl: string;
  mobileSideImageUrl: string;
  header: string;
  required: boolean;
  timeLimit: number;
  feedback: Feedback | null;
  sessionRecord?: GameRecordSessionRecord | null;
}

export default class GameRecord extends Stage {
  readonly title?: string;
  readonly body: string;
  readonly recordId: string;
  readonly header?: string;
  readonly required: boolean;
  readonly timeLimit: number;
  readonly feedback: Feedback | null;
  readonly sessionRecord?: GameRecordSessionRecord | null;
  readonly instruction?: string;
  readonly sideImageUrl?: string;
  readonly mobileSideImageUrl?: string;

  constructor({
    id,
    type,
    order,
    title,
    body,
    completedAt,
    recordId,
    badge,
    instruction,
    sideImageUrl,
    mobileSideImageUrl,
    header,
    required,
    timeLimit,
    feedback,
    sessionRecord,
  }: Props) {
    super({ id, type, order, completedAt, badge });

    this.title = title;
    this.body = body;
    this.recordId = recordId;
    this.instruction = instruction;
    this.sideImageUrl = sideImageUrl;
    this.mobileSideImageUrl = mobileSideImageUrl;
    this.header = header;
    this.required = required;
    this.timeLimit = timeLimit;
    this.feedback = feedback;
    this.sessionRecord = sessionRecord;
  }

  hasSessionRecord(): boolean {
    return this.sessionRecord !== null;
  }
}
