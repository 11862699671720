import CS from '@/locales/cs';
import DE from '@/locales/de';
import EN from '@/locales/en';
import FR from '@/locales/fr';
import HU from '@/locales/hu';
import IT from '@/locales/it';
import NL from '@/locales/nl';
import PL from '@/locales/pl';
import RO from '@/locales/ro';
import SK from '@/locales/sk';
import HR from '@/locales/hr';
import PT from '@/locales/pt';
import SL from '@/locales/sl';
import UK from '@/locales/uk';

export default {
  CS: CS,
  DE: DE,
  EN: EN,
  FR: FR,
  HU: HU,
  IT: IT,
  NL: NL,
  PL: PL,
  RO: RO,
  SK: SK,
  HR: HR,
  PT: PT,
  SL: SL,
  UK: UK,
};
