import { ref } from 'vue';
import type { Ref } from 'vue';
import { defaultOptions, usePrimeVue } from 'primevue/config';

export function useLocaleConfig() {
  const primevue = usePrimeVue();

  let primeVueLocaleFiles: Record<string, () => Promise<{ default: any }>> = {};
  const localeOptions: Ref<string[]> = ref([]);

  primeVueLocaleFiles = import.meta.glob('../../node_modules/primelocale/*.json') as Record<
    string,
    () => Promise<{ default: any }>
  >;

  localeOptions.value = Object.keys(primeVueLocaleFiles)
    .map((e) => e.match(/\/([^/]+)\.json$/)?.[1] || '')
    .filter((e) => e.split('-')[0].length === 2);

  async function setPrimeVueLocaleConfig(currentLocale?: string) {
    if (!currentLocale) return;

    const localeRe = new RegExp(`\\b${currentLocale}.json$`, 'i');
    const langRe = new RegExp(`\\b${currentLocale.split('-')[0]}.json$`, 'i');
    let localeFileName = Object.keys(primeVueLocaleFiles).find((locale) => localeRe.test(locale));
    localeFileName ??= Object.keys(primeVueLocaleFiles).find((locale) => langRe.test(locale));

    if (localeFileName) {
      const localeFile =
        Object.values((await primeVueLocaleFiles[localeFileName]()).default)[0] ??
        defaultOptions.locale;

      primevue.config.locale = {
        ...primevue.config.locale,
        ...localeFile,
      } as any;
    }
  }

  return {
    setPrimeVueLocaleConfig,
  };
}
