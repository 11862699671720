import { useSettingsStore } from '@/stores/settingsStore';
import { FAQ, NOT_FOUND } from '@/router/routes.names';
import type { RouteRecordRaw } from 'vue-router';

const faqRoutes: RouteRecordRaw[] = [
  {
    path: 'faq',
    component: () => import('@/layouts/GameLayout.vue'),
    children: [
      {
        path: '',
        name: FAQ,
        component: () => import('@/views/Faq.vue'),
        beforeEnter: async (to, from, next) => {
          const settingsStore = useSettingsStore();
          if (!settingsStore.hasFrequentlyAskedQuestionsData) {
            next({ name: NOT_FOUND });

            return;
          }

          next();
        },
      },
    ],
  },
];

export default faqRoutes;
