interface Props {
  invalid?: boolean;
  disabled?: boolean;
  display?: string;
  modelValue?: any[];
}

interface State {
  focused: boolean;
}

interface Context {
  focused: boolean;
  selected: boolean;
}

export default {
  root: ({ props, state }: { props: Props; state: State }) => ({
    class: [
      // Display and Position
      'inline-flex',
      'relative',
      'w-full',

      // Shape
      'rounded-md',

      // Color and Background
      'bg-white',
      'border',
      { 'border-neutral-200': !props.invalid },

      // Invalid State
      { 'border-red-500': props.invalid },

      // Transitions
      'transition-all',
      'duration-200',

      // States
      { 'hover:border-neutral-300': !props.invalid },
      {
        'outline-none outline-offset-0 primary-ring-color': state.focused,
      },

      // Misc
      'cursor-pointer',
      'select-none',
      {
        'opacity-60': props.disabled,
        'pointer-events-none': props.disabled,
        'cursor-default': props.disabled,
      },
    ],
  }),
  labelContainer: 'overflow-hidden flex flex-auto cursor-pointer',
  label: ({ props }: { props: Props }) => ({
    class: [
      'leading-[normal]',
      'flex flex-wrap ',

      // Spacing
      {
        'p-4': props.display !== 'chip',
        'py-4 px-4':
          props.display === 'chip' && (!props?.modelValue || props?.modelValue?.length === 0),
        'py-1.5 px-3':
          props.display === 'chip' && props?.modelValue && props?.modelValue?.length > 0,
      },

      // Color
      {
        'text-neutral-700': props.modelValue?.length,
        'text-neutral-400': !props.modelValue?.length,
      },
      'placeholder:text-neutral-400',

      // Transitions
      'transition duration-200',

      // Misc
      'overflow-hidden whitespace-nowrap cursor-pointer overflow-ellipsis',
    ],
  }),
  dropdown: {
    class: [
      // Flexbox
      'flex items-center justify-center',
      'shrink-0',

      // Color and Background
      'bg-transparent',
      'text-neutral-500',

      // Size
      'w-12',

      // Shape
      'rounded-tr-md',
      'rounded-br-md',
    ],
  },
  overlay: {
    class: [
      // Position

      // Shape
      'border-0',
      'rounded-md',
      'shadow-md',

      // Color
      'bg-white',
      'text-neutral-800',
    ],
  },
  header: {
    class: [
      'flex items-center justify-between',
      // Spacing
      'py-3 px-5 gap-2',
      'm-0',

      //Shape
      'border-b',
      'rounded-tl-md',
      'rounded-tr-md',

      // Color
      'text-neutral-700',
      'bg-neutral-100',
      'border-neutral-300',

      '[&_[data-pc-name=pcfiltercontainer]]:!flex-auto',
      '[&_[data-pc-name=pcfilter]]:w-full',
    ],
  },
  listContainer: {
    class: [
      // Sizing
      'max-h-[200px]',

      // Misc
      'overflow-auto',
    ],
  },
  list: {
    class: 'py-3 list-none m-0',
  },
  option: ({ context }: { context: Context }) => ({
    class: [
      // Font
      'font-normal',
      'leading-none',

      // Flexbox
      'flex items-center',

      // Position
      'relative',

      // Shape
      'border-0',
      'rounded-none',

      // Spacing
      'm-0',
      'py-3 px-5 gap-2',

      // Color
      { 'text-neutral-700': !context.focused && !context.selected },
      {
        'bg-neutral-200 text-neutral-700': context.focused && !context.selected,
      },
      {
        'bg-neutral-100 text-neutral-700': context.focused && context.selected,
      },
      {
        'bg-neutral-50 text-neutral-700': !context.focused && context.selected,
      },

      //States
      {
        'hover:bg-neutral-100': !context.focused && !context.selected,
      },
      {
        'hover:text-neutral-700 hover:bg-neutral-100': context.focused && !context.selected,
      },

      // Transitions
      'transition-shadow',
      'duration-200',

      // Misc
      'cursor-pointer',
      'overflow-hidden',
      'whitespace-nowrap',
    ],
  }),
  optionGroup: {
    class: [
      //Font
      'font-bold',

      // Spacing
      'm-0',
      'p-3 px-5',

      // Color
      'text-neutral-800',
      'bg-white',

      // Misc
      'cursor-auto',
    ],
  },
  emptyMessage: {
    class: [
      // Font
      'leading-none',

      // Spacing
      'py-3 px-5',

      // Color
      'text-neutral-800',
      'bg-transparent',
    ],
  },
  loadingIcon: {
    class: 'text-neutral-400 animate-spin',
  },
  transition: {
    enterFromClass: 'opacity-0 scale-y-[0.8]',
    enterActiveClass:
      'transition-[transform,opacity] duration-[120ms] ease-[cubic-bezier(0,0,0.2,1)]',
    leaveActiveClass: 'transition-opacity duration-100 ease-linear',
    leaveToClass: 'opacity-0',
  },
};
