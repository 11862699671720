export const LocaleCodes = {
  CS: 'CS',
  EN: 'EN',
  PL: 'PL',
  DE: 'DE',
  FR: 'FR',
  HU: 'HU',
  IT: 'IT',
  NL: 'NL',
  RO: 'RO',
  SK: 'SK',
  HR: 'HR',
  PT: 'PT',
  SL: 'SL',
  UK: 'UK',
} as const;

export type LocaleCode = (typeof LocaleCodes)[keyof typeof LocaleCodes];

export interface Language {
  value: LocaleCode;
  name: string;
}
