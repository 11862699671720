import { immerable, produce } from 'immer';
import Badge from '@/domain/entities/flow/Badge';

interface Props {
  id: string;
  type: string;
  order: number;
  badge?: Badge | null;
  completedAt: Date | null;
}

export default abstract class Stage {
  [immerable] = true;

  readonly id: string;
  readonly type: string;
  readonly order: number;
  readonly badge?: Badge | null;
  readonly completedAt: Date | null;

  constructor({ id, type, order, badge, completedAt }: Props) {
    this.id = id;
    this.type = type;
    this.order = order;
    this.badge = badge;
    this.completedAt = completedAt;
  }

  markBadgeAsCompleted(): Stage {
    return produce(this, (draft) => {
      draft.badge = draft.badge?.markAsCompleted();
    });
  }

  markAsCompleted(completedAt: Date): Stage {
    return produce(this, (draft) => {
      draft.completedAt = completedAt;
    });
  }

  possibleBadge(): Badge | undefined | null {
    return this.badge;
  }

  get isCompleted(): boolean {
    return !!this.completedAt;
  }
}
