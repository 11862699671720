export default {
  name: '313C',
  recruitment_game: 'Náborová hra',
  back: 'Spáát',
  cancel: 'Zrušiť',
  continue: 'Pokračovať',
  skip: 'Preskočiť',
  start: 'Štart',
  close: 'Zavrieť',
  help: 'Pomoc',
  time_left: 'Zostávajúci čas',
  progress: 'Postup',
  privacy_policy: 'Ochrana osobných údajov',
  cookies_policy: 'Politika cookies a Podmienky',
  faq: 'Otázky a odpovede',
  messages: 'Správy',
  unread: 'neprecitnutá',
  start_recording: 'Spustenie nahrávania',
  stop_recording: 'Zastaviť nahrávanie',
  remove_record: 'Odstrániť záznam',
  play_video: 'Prehrať',
  stop_video: 'Zastaviť',
  mute_video: 'Stíšiť',
  unmute_video: 'Obnoviť zvuk',
  replay_video: 'Prehrať znova',
  choose: 'Vybrať',
  badges: {
    title: 'Odznaky',
    badge: 'Odznak',
    my_badges: 'Moje Odznaky',
    received: 'Získate odznak',
    continue: 'Pokračujte v hraní',
    show_badges: 'Zobraziť odznaky',
    show_badge: 'Pozri si odmeňu',
  },
  record_test: {
    intro: 'TEST NASTAVENÍ KAMERY A MIKROFÓNU',
    content:
      'Pred začatím s nástrojom si otestujte svoje nastavenia. V poli nižšie by ste mali vidieť obraz zo svojej kamery. Ak ho vidíte, znamená to, že vaše nastavenia sú pravdepodobne správne - pre istotu nahrajte a prehraťe video. Ak nevidíte obraz z kamery, nemôžete prehrať nahrávku alebo máte iné pochybnosti, kliknite na tlačidlo „RIEŠENIE PROBLÉMOV“.',
    troubleshoot: 'RIEŠENIE PROBLÉMOV',
  },
  questions: {
    progress: 'Otázka',
    accept: 'Potvrdiť',
    question_has_already_been_answered: 'Táto otázka už bola zodpovedaná.',
  },
  not_found: {
    code: '404',
    title: 'Stránka neexistuje',
    content:
      'Stránka, ktorú ste hľadali, nebola nájdená. Mohlo byť odstránené, meno bolo zmenené alebo to vôbec neexistovalo',
  },
  phone: 'Číslo telefónu',
  select_language: 'Vyberte jazyk / Choose language',
  title:
    'Táto stránka používa súbory cookie na zabezpečenie vynikajúceho užívateľského zážitku. Používaním tejto stránky súhlasíte s používaním',
  link: 'súborov cookie',
  button: 'Zavrieť',
  warning: {
    title: 'Došla k chybe',
    content: 'Skúste to znovu a ak sa chyba bude opakovať, dajte nám vedieť na',
    content_without_email:
      'Skúste to znovu a ak sa chyba bude opakovať, dajte nám vedieť na našu technickú podporu',
    confirm: 'Zavrieť',
  },
  end_time: {
    title: 'Nahlásiť chybu',
    content: 'Ak sa počas hrania objaví chyba, kontaktujte nás prosím e-mailom na adresu:',
  },
  record_video_error: {
    title: 'Žiadny prístup k fotoaparátu a/alebo mikrofónu.',
    content:
      'Pre dokončenie tohto úlohy zmeňte svoje nastavenia a dovoľte aplikácii prístup k fotoaparátu a/alebo mikrofónu.',
  },
  unsupported_os: {
    content: `
      <span class="swal2-title">Nepodporované zariadenie</span>
      <hr>
      <div class="swal2-wrapper">
        <p>Pre pokračovanie, prosím, použite <strong>iné zariadenie a/alebo iný prehliadač.</strong></p>
        <p>Nástroj na nábor, ktorý sa chystáte použiť, obsahuje aspoň jednu úlohu, pri ktorej budete musieťnahrávať svoju odpoveď vo formáte videa. Bohužiaľ, nie všetky zariadenia a/alebo prehliadačeumožňujú tento krok vykonať správne.</p>
        <p><strong>Ak vidíte túto obrazovku, znamená to, že vaše zariadenie a/alebo prehliadač nedokážu zaručiťsprávne nahratie a odoslanie video súboru.</strong> Nižšie nájdete odporúčané nastavenia.</p>
        <br>
        <p><strong>Stolné zariadenia:</strong></p>
        <p>Windows: Google Chrome, Firefox, Opera, MS Edge</p>
        <p>macOS: Google Chrome, Firefox, Opera, Safari, MS Edge</p>
        <p>Ubuntu: Google Chrome, Firefox, Opera</p>
        <br>
        <p><strong>Mobilné zariadenia:</strong></p>
        <p>Andriod: Google Chrome, Firefox, Opera</p>
        <p>iOS (iPhone/iPad): Safari</p>
      </div>
      <hr>
    `,
  },
  skip_stage_without_record: {
    title: 'Ste si istý, že chcete úlohu preskočiť?',
    content:
      '<p>Nahrávanie videoodpovede na túto úlohu nie je povinné, ale odporúča sa - pomôže nám lepšie vás spoznať. Ste si istí, že chcete preskočiť nahrávanie videoodpovede?</p>',
  },
  skip_stage_with_record: {
    title: 'Ste si istý, že chcete úlohu preskočiť?',
    content:
      '<p>Máte v úmysle preskočiť uloženie nahraného videoodpovede - nebude dostupné osobe vykonávajúcej výberový proces. Nahranie videoodpovede pre túto úlohu nie je povinné, ale odporúča sa - pomôže nám lepšie vás spoznať. Ste si istí, že chcete preskočiť uloženie nahraného videoodpovede?</p>',
  },
  instruction_camera: {
    content: `
      <span class="swal2-title">Riešenie problémov s kamerou a mikrofónom</span>
      <hr>
      <div class="swal2-wrapper">
      <p>Ak nahrávanie alebo prehrávanie nie je správne, môžete nasledovať niekoľko krokov, ktoré by mali problém vyriešiť. Najčastejšie príčiny problémov vyplývajú z nastavení zariadenia, vrátane iných programov alebo aplikácií, ktoré súčasne používajú kameru a/alebo mikrofón.</p>
      <p>Možné riešenia problému:</p>
      <ol>
        <li>Uistite sa, že prehliadač má povolenia na používanie mikrofónu a kamery. Ak nie, zmeňte nastavenia a obnovte stránku.</li>
        <li>Uistite sa, že žiadna iná aplikácia alebo program práve nepoužíva kameru/mikrofón (napr. MS Teams, Photo Booth alebo FaceTime) - zatvorte aplikácie, ktoré blokujú možnosť použitia kamery prehliadačom a obnovte stránku.</li>
        <li>Skontrolujte, či je váš prehliadač aktualizovaný na najnovšiu verziu - ak nie, vykonajte aktualizáciu a test znova.</li>
        <li>Ak predchádzajúce kroky nepomohli, skúste použiť iný prehliadač alebo zariadenie.</li>
      </ol>
      <p>Ak problém pretrváva, dajte nám vedieť na { supportEmail } posielaním informácií o zariadení a prehliadači, na ktorých sa problém vyskytuje.</p>
      </div>
      <hr>
    `,
  },
  recaptcha: 'Zaškrtnite Recaptcha',
  phone_incorrect: 'Vložte platné telefónne číslo',
  rotate_phone_video: 'Otočte telefón vodorovne, aby ste zobrazili video vo väčšej veľkosti',
  rotate_phone_image: 'Otočte telefón vodorovne, aby ste zobrazili obrázok vo väčšej veľkosti',
};
