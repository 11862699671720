import { NOT_FOUND } from '@/router/routes.names';
import type { RouteRecordRaw } from 'vue-router';

const errorRoutes: RouteRecordRaw[] = [
  {
    path: '/:pathMatch(.*)*',
    name: NOT_FOUND,
    component: () => import('@/views/404.vue'),
  },
];

export default errorRoutes;
