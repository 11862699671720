import { immerable, produce } from 'immer';
import GameQuestionAnswer from '@/domain/entities/flow/GameQuestionAnswer';
import Badge from '@/domain/entities/flow/Badge';
import GameQuestionSessionAnswer from '@/domain/entities/flow/GameQuestionSessionAnswer';
import Feedback from '@/domain/entities/flow/Feedback';

interface Props {
  id: string;
  body: string | null;
  feedback: Feedback | null;
  badge: Badge | null;
  sessionAnswer: GameQuestionSessionAnswer | null;
  chosen: boolean;
}

export default class ClosedGameQuestionAnswer extends GameQuestionAnswer {
  [immerable] = true;

  readonly chosen: boolean;

  constructor({ id, body, feedback, badge, sessionAnswer, chosen }: Props) {
    super({ id, body, feedback, badge, sessionAnswer });
    this.chosen = chosen;
  }

  choose(): ClosedGameQuestionAnswer {
    return produce(this, (draft) => {
      draft.chosen = true;
    });
  }

  unChoose(): ClosedGameQuestionAnswer {
    return produce(this, (draft) => {
      draft.chosen = false;
    });
  }

  updateSessionAnswer(sessionAnswer: GameQuestionSessionAnswer): ClosedGameQuestionAnswer {
    return produce(this, (draft) => {
      draft.sessionAnswer = sessionAnswer;
    });
  }

  markBadgeAsCompleted(): GameQuestionAnswer {
    return produce(this, (draft) => {
      const currentBadge = draft.badge;
      if (currentBadge && 'markAsCompleted' in currentBadge) {
        draft.badge = currentBadge.markAsCompleted();
      }
    });
  }
}
