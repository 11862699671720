export class VideoPreloader {
  private videoLoadStatusMap: Map<string, boolean> = new Map();

  public async preload(videos: string[], concurrentLoads: number = 2): Promise<void> {
    this.addVideosToLoadStatusMap(videos);
    const queue = [...videos];

    const loaders = Array.from({ length: concurrentLoads }, () => this.loadNextVideo(queue));
    await Promise.all(loaders);
  }

  private addVideosToLoadStatusMap(videos: string[]): void {
    videos.forEach((video) => {
      if (!this.videoLoadStatusMap.has(video)) {
        this.videoLoadStatusMap.set(video, false);
      }
    });
  }

  private async loadNextVideo(queue: string[]): Promise<void> {
    while (queue.length > 0) {
      const video = queue.shift();
      if (video && this.videoLoadStatusMap.get(video) === false) {
        await this.preloadVideo(video);
      }
    }
  }

  private preloadVideo(videoSrc: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const videoElement = document.createElement('video');
      videoElement.src = videoSrc;
      videoElement.preload = 'auto';
      this.markVideoAsLoaded(videoSrc);

      videoElement.onerror = () => {
        this.markVideoAsNotLoaded(videoSrc);
        console.error(`Error loading video: ${videoSrc}`);
        resolve();
      };
    });
  }

  private markVideoAsLoaded(videoSrc: string): void {
    if (this.videoLoadStatusMap.has(videoSrc)) {
      this.videoLoadStatusMap.set(videoSrc, true);
    }
  }

  private markVideoAsNotLoaded(videoSrc: string): void {
    if (this.videoLoadStatusMap.has(videoSrc)) {
      this.videoLoadStatusMap.set(videoSrc, false);
    }
  }
}
