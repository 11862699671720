import { immerable, produce } from 'immer';
import GameQuestion from '@/domain/entities/flow/GameQuestion';
import GameRecord from '@/domain/entities/flow/GameRecord';
import GameMessage from '@/domain/entities/flow/GameMessage';
import Stage from '@/domain/entities/flow/Stage';
import Badge from '@/domain/entities/flow/Badge';

function isGameMessage(stage: Stage): stage is GameMessage {
  return stage instanceof GameMessage;
}

export default class Flow {
  [immerable] = true;

  stages: Stage[];

  constructor(stages: Stage[]) {
    this.stages = stages;
  }

  updateStage(stage: Stage): Flow {
    const stageIndex = this.stages.findIndex((findStage) => findStage.id === stage.id);

    return produce(this, (draft) => {
      draft.stages[stageIndex] = stage;
    });
  }

  messagesToCurrentStage(): GameMessage[] {
    // @ts-ignore
    return this.stages.filter(
      (stage, index) =>
        isGameMessage(stage) && (stage.isCompleted || index === this.currentStageIndex())
    );
  }

  percentageCompleted(): number {
    return Math.round((this.completedStagesCount() / this.stages.length) * 100) / 100;
  }

  completedStagesCount(): number {
    return this.stages.filter((stage) => stage.isCompleted).length;
  }

  questionsCount(): number {
    return this.stages.filter(
      (stage) => stage instanceof GameQuestion || stage instanceof GameRecord
    ).length;
  }

  hasQuestions(): boolean {
    return !!this.stages.filter(
      (stage) => stage instanceof GameQuestion || stage instanceof GameRecord
    ).length;
  }

  completedQuestionsCount(): number {
    return this.stages.filter(
      (stage) => (stage instanceof GameQuestion || stage instanceof GameRecord) && stage.isCompleted
    ).length;
  }

  currentStage(): Stage | undefined {
    return this.stages.find((stage) => !stage.isCompleted);
  }

  currentStageIndex(): number {
    return this.stages.findIndex((stage) => !stage.isCompleted);
  }

  nextStage(): Stage | undefined {
    return this.stages[this.currentStageIndex() + 1];
  }

  areAllStagesCompleted(): boolean {
    return this.stages.every((stage) => stage.isCompleted);
  }

  completedBadgesCount(): number {
    return this.badges().filter((badge) => badge.isCompleted).length;
  }

  badges(): Badge[] {
    return this.stages
      .map((stage) => stage.possibleBadge())
      .filter((badge): badge is Badge => badge !== undefined && badge !== null);
  }

  hasGameRecords(): boolean {
    return this.stages.some((stage) => stage instanceof GameRecord);
  }
}
