import { immerable, produce } from 'immer';
import GameQuestion from '@/domain/entities/flow/GameQuestion';
import BestWorstGameQuestionAnswer from '@/domain/entities/flow/BestWorstGameQuestionAnswer';
import Badge from '@/domain/entities/flow/Badge';
import GameQuestionSessionAnswer from '@/domain/entities/flow/GameQuestionSessionAnswer';
import { BEST_TYPE_NAME, WORST_TYPE_NAME } from '@/names/gameQuestion.names';

interface Props {
  id: string;
  type: string;
  order: number;
  body: string;
  answers: BestWorstGameQuestionAnswer[];
  completedAt: Date | null;
  questionId: string;
  badge: Badge | null;
  instruction: string;
  imageUrl: string;
  sideImageUrl: string;
  mobileSideImageUrl: string;
  header: string;
}

export default class BestWorstGameQuestion extends GameQuestion {
  [immerable] = true;

  readonly answers: BestWorstGameQuestionAnswer[];

  constructor({
    id,
    type,
    order,
    body,
    answers,
    completedAt,
    questionId,
    badge,
    instruction,
    imageUrl,
    sideImageUrl,
    mobileSideImageUrl,
    header,
  }: Props) {
    super({
      id,
      type,
      order,
      body,
      completedAt,
      questionId,
      badge,
      instruction,
      imageUrl,
      sideImageUrl,
      mobileSideImageUrl,
      header,
    });

    this.answers = answers;
  }

  markBadgeAsCompleted(): BestWorstGameQuestion {
    return produce(this, (draft: any) => {
      const chosenBestAnswer = this.chosenBestAnswer();
      if (chosenBestAnswer?.badge) {
        const answerIndex = draft.answers.findIndex(
          (findAnswer: BestWorstGameQuestionAnswer) => findAnswer.id === chosenBestAnswer.id
        );
        draft.answers[answerIndex] = draft.answers[answerIndex].markBadgeAsCompleted();

        return;
      }
      if (draft.badge) {
        draft.badge = draft.badge.markAsCompleted();
      }
    });
  }

  chosenBestAnswer(): BestWorstGameQuestionAnswer | undefined {
    return this.answers.find((answer) => answer.chosenAsBestAnswer);
  }

  chosenWorstAnswer(): BestWorstGameQuestionAnswer | undefined {
    return this.answers.find((answer) => answer.chosenAsWorstAnswer);
  }

  hasChosenAnswer(): boolean {
    return (
      this.answers.some((answer) => answer.chosenAsBestAnswer) &&
      this.answers.some((answer) => answer.chosenAsWorstAnswer)
    );
  }

  hasSessionAnswer(): boolean {
    return this.answers.some((answer: BestWorstGameQuestionAnswer) => answer.sessionAnswer);
  }

  chooseBestAnswer(answer: BestWorstGameQuestionAnswer): BestWorstGameQuestion {
    const answerIndex = this.answers.findIndex(
      (findAnswer: BestWorstGameQuestionAnswer) => findAnswer.id === answer.id
    );
    return produce(this, (draft: any) => {
      draft.answers[answerIndex] = draft.answers[answerIndex].unChoose();
      draft.answers = draft.answers.map((mapAnswer: BestWorstGameQuestionAnswer, index: number) =>
        answerIndex === index ? mapAnswer.chooseBestAnswer() : mapAnswer.unChooseBestAnswer()
      );
    });
  }

  chooseWorstAnswer(answer: BestWorstGameQuestionAnswer): BestWorstGameQuestion {
    const answerIndex = this.answers.findIndex(
      (findAnswer: BestWorstGameQuestionAnswer) => findAnswer.id === answer.id
    );
    return produce(this, (draft: any) => {
      draft.answers[answerIndex] = draft.answers[answerIndex].unChoose();
      draft.answers = draft.answers.map((mapAnswer: BestWorstGameQuestionAnswer, index: number) =>
        answerIndex === index ? mapAnswer.chooseWorstAnswer() : mapAnswer.unChooseWorstAnswer()
      );
    });
  }

  chosenAnswersRequestData(): { answer_id: string; answer: string }[] {
    return [
      {
        answer_id: this.chosenBestAnswer()?.id!,
        answer: BEST_TYPE_NAME,
      },
      {
        answer_id: this.chosenWorstAnswer()?.id!,
        answer: WORST_TYPE_NAME,
      },
    ];
  }

  updateSessionAnswers(sessionAnswers: GameQuestionSessionAnswer[]): BestWorstGameQuestion {
    const sessionBestAnswer = sessionAnswers.find((answer) => answer.answer === BEST_TYPE_NAME);
    const sessionWorstAnswer = sessionAnswers.find((answer) => answer.answer === WORST_TYPE_NAME);

    return produce(this, (draft) => {
      draft.answers.forEach((answer) => {
        if (answer.id === draft.chosenBestAnswer()?.id && sessionBestAnswer) {
          answer.updateSessionAnswer(sessionBestAnswer);
        } else if (answer.id === draft.chosenWorstAnswer()?.id && sessionWorstAnswer) {
          answer.updateSessionAnswer(sessionWorstAnswer);
        }
      });
    });
  }

  possibleBadge(): Badge | null {
    if (this.badge) return this.badge;

    return this.answers.find((answer) => answer.badge)?.badge ?? null;
  }
}
