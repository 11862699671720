import { defineRule, configure } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import {
  required,
  min,
  max,
  numeric,
  size,
  min_value,
  max_value,
  email,
} from '@vee-validate/rules';

defineRule('required', required);
defineRule('min', min);
defineRule('max', max);
defineRule('numeric', numeric);
defineRule('size', size);
defineRule('min_value', min_value);
defineRule('max_value', max_value);
defineRule('email', email);

configure({
  generateMessage: localize({
    cs: {
      messages: {
        required: 'Zadejte: {field}',
        min: 'Minimální počet znaků je: 0:{length}',
        max: 'Maximální počet znaků je: 0:{length}',
        numeric: '{field} je povinné a může obsahovat pouze celá čísla',
        size: 'Maximální velikost souboru je 0:{size}KB',
        min_value: 'Minimální hodnota musí být 0:{min} nebo více',
        max_value: 'Maximální hodnota musí být 0:{max} nebo méně',
        email: 'Nesprávný formát',
      },
    },
    de: {
      messages: {
        required: 'Geben Sie ein: {field}',
        min: 'Mindestanzahl der Zeichen beträgt: 0:{length}',
        max: 'Maximale Anzahl der Zeichen beträgt: 0:{length}',
        numeric: '{field} ist erforderlich und kann nur Ganzzahlen enthalten',
        size: 'Maximaler Dateigröße beträgt 0:{size}KB sein',
        min_value: 'Der minimale Wert muss 0:{min} oder mehr betragen',
        max_value: 'Der maximale Wert muss 0:{max} oder weniger betragen',
        email: 'Ungültiges Format',
      },
    },
    en: {
      messages: {
        required: 'Enter: {field}',
        min: 'The minimum number of characters is: 0:{length}',
        max: 'The maximum number of characters is: 0:{length}',
        numeric: '{field} is required and can only contain whole numbers',
        size: 'The maximum file size is 0:{size}KB',
        min_value: 'The minimum value must be 0:{min} or more',
        max_value: 'The maximum value must be 0:{max} or less',
        email: 'Invalid format',
      },
    },
    fr: {
      messages: {
        required: 'Entrer: {field}',
        min: 'Le nombre minimum de caractères est: 0:{length}',
        max: 'Le nombre maximum de caractères est: 0:{length}',
        numeric: '{field} est obligatoire et ne peut contenir que des nombres entiers',
        size: 'La taille maximum du fichier est 0:{size}KB',
        min_value: 'La valeur minimale doit être de 0:{min} ou plus',
        max_value: 'La valeur maximale doit être de 0:{max} ou moins',
        email: 'Format non valide',
      },
    },
    hr: {
      messages: {
        required: 'Unesite: {field}',
        min: 'Minimalan broj znakova je: 0:{length}',
        max: 'Maksimalan broj znakova je: 0:{length}',
        numeric: '{field} je obavezno i može sadržavati samo cijele brojeve',
        size: 'Maksimalna veličina datoteke je 0:{size}KB',
        min_value: 'Minimalna vrijednost mora biti 0:{min} ili više',
        max_value: 'Maksimalna vrijednost mora biti 0:{max} ili manje',
        email: 'Neispravan format',
      },
    },
    hu: {
      messages: {
        required: 'Írja be: {field}',
        min: 'A minimum karakterszáma van: 0:{length}',
        max: 'A maximum karakterszáma van: 0:{length}',
        numeric: '{field} mező kötelező és csak egész számokat tartalmazhat',
        size: 'A legnagyobb fájlméret 0:{size}KB',
        min_value: 'A minimális érték legalább 0:{min} kell legyen',
        max_value: 'A maximális érték legfeljebb 0:{max} lehet',
        email: 'Érvénytelen formátum',
      },
    },
    it: {
      messages: {
        required: 'Scrivi: {field}',
        min: 'Il numero minimo di caratteri è: 0:{length}',
        max: 'Il numero massimo di caratteri è: 0:{length}',
        numeric: '{field} è obbligatorio e può contenere solo numeri interi',
        size: 'La dimensione massima del file è 0:{size}KB',
        min_value: 'Il valore minimo deve essere 0:{min} o più',
        max_value: 'Il valore massimo deve essere 0:{max} o meno',
        email: 'Formato non valido',
      },
    },
    nl: {
      messages: {
        required: 'Typ: {field}',
        min: 'Minimum aantal tekens is: 0:{length}',
        max: 'Maximum aantal tekens is: 0:{length}',
        numeric: '{field} is verplicht en mag alleen hele getallen bevatten',
        size: 'Maximum bestandsgrootte is 0:{size}KB',
        min_value: 'De minimale waarde moet 0:{min} of meer zijn',
        max_value: 'De maximale waarde moet 0:{max} of minder zijn',
        email: 'Ongeldig formaat',
      },
    },
    pl: {
      messages: {
        required: 'Wpisz: {field}',
        min: 'Minimalna ilość znaków wynosi: 0:{length}',
        max: 'Maksymalna ilość znaków wynosi: 0:{length}',
        numeric: '{field} jest wymagane i może zawierać wyłącznie liczby całkowite',
        size: 'Maksymalny rozmiar pliku wynosi 0:{size}KB',
        min_value: 'Minimalna wartość musi wynosić 0:{min} lub więcej',
        max_value: 'Maksymalna wartość musi wynosić 0:{max} lub mniej',
        email: 'Nieprawidłowy format',
      },
    },
    pt: {
      messages: {
        required: 'Digite: {field}',
        min: 'O número mínimo de caracteres é: 0:{length}',
        max: 'O número máximo de caracteres é: 0:{length}',
        numeric: '{field} é obrigatório e pode conter apenas números inteiros',
        size: 'O tamanho máximo do arquivo é 0:{size}KB',
        min_value: 'O valor mínimo deve ser 0:{min} ou mais',
        max_value: 'O valor máximo deve ser 0:{max} ou menos',
        email: 'Formato inválido',
      },
    },
    ro: {
      messages: {
        required: 'Introduceți: {field}',
        min: 'Numărul minim de caractere este: 0:{length}',
        max: 'Numărul maxim de caractere este: 0:{length}',
        numeric: '{field} este obligatoriu și poate conține doar numere întregi',
        size: 'Dimensiunea maximă a fișierului este 0:{size}KB',
        min_value: 'Valoarea minimă trebuie să fie 0:{min} sau mai mare',
        max_value: 'Valoarea maximă trebuie să fie 0:{max} sau mai mică',
        email: 'Format incorect',
      },
    },
    sl: {
      messages: {
        required: 'Vnesite: {field}',
        min: 'Minimalno število znakov je: 0:{length}',
        max: 'Maksimalno število znakov je: 0:{length}',
        numeric: '{field} je obvezno in lahko vsebuje le cela števila',
        size: 'Največja velikost datoteke je 0:{size}KB',
        min_value: 'Najmanjša vrednost mora biti 0:{min} ali več',
        max_value: 'Največja vrednost mora biti 0:{max} ali manj',
        email: 'Nepravilen format',
      },
    },
    sk: {
      messages: {
        required: 'Vložte: {field}',
        min: 'Minimálny počet znakov je: 0:{length}',
        max: 'Maximálny počet znakov je: 0:{length}',
        numeric: '{field} je povinné a môže obsahovať iba celé čísla',
        size: 'Maximálna veľkosť súboru je 0:{size}KB',
        min_value: 'Minimálna hodnota musí byť 0:{min} alebo viac',
        max_value: 'Maximálna hodnota musí byť 0:{max} alebo menej',
        email: 'Nesprávny formát',
      },
    },
    uk: {
      messages: {
        required: 'Введіть: {field}',
        min: 'Мінімальна кількість символів становить: 0:{length}',
        max: 'Максимальна кількість символів становить: 0:{length}',
        numeric: "{field} є обов'язковим і може містити лише цілі числа",
        size: 'Максимальний розмір файлу становить 0:{size}KB',
        min_value: 'Максимальне значення має бути 0:{min} або менше',
        max_value: 'Мінімальне значення має бути 0:{max} або більше',
        email: 'Невірний формат',
      },
    },
  }),
});
