import Stage from '@/domain/entities/flow/Stage';
import Badge from '@/domain/entities/flow/Badge';
import GameQuestionSessionAnswer from '@/domain/entities/flow/GameQuestionSessionAnswer';

interface Props {
  id: string;
  type: string;
  order: number;
  body: string;
  completedAt: Date | null;
  questionId: string;
  badge: Badge | null;
  instruction: string;
  imageUrl: string;
  sideImageUrl: string;
  mobileSideImageUrl: string;
  header: string;
}

export default abstract class GameQuestion extends Stage {
  readonly questionId: string;
  readonly body: string;
  readonly instruction?: string;
  readonly imageUrl?: string;
  readonly sideImageUrl?: string;
  readonly mobileSideImageUrl?: string;
  readonly header: string;

  constructor({
    id,
    type,
    order,
    body,
    completedAt,
    questionId,
    badge,
    instruction,
    imageUrl,
    sideImageUrl,
    mobileSideImageUrl,
    header,
  }: Props) {
    super({ id, type, order, completedAt, badge });

    this.questionId = questionId;
    this.body = body;
    this.instruction = instruction;
    this.imageUrl = imageUrl;
    this.sideImageUrl = sideImageUrl;
    this.mobileSideImageUrl = mobileSideImageUrl;
    this.header = header;
  }

  abstract updateSessionAnswers(sessionAnswers: GameQuestionSessionAnswer[]): void;
  abstract chosenAnswersRequestData(): { answer_id: string; answer: string | null }[];
}
