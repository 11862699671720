import { createRouter, createWebHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import { useLayoutStore } from '@/stores/layoutStore';
import { useSessionStore } from '@/stores/sessionStore';
import { useSettingsStore } from '@/stores/settingsStore';
import MainLayout from '@/layouts/MainLayout.vue';
import authRoutes from '@/router/auth';
import badgesRoutes from '@/router/badges';
import faqRoutes from '@/router/faq';
import welcomeRoutes from '@/router/welcome';
import finishRoutes from '@/router/finish';
import gameRoutes from '@/router/game';
import documentsRoutes from '@/router/documents';
import recordTestRoutes from '@/router/recordTest';
import errorRoutes from '@/router/error';
import { env } from '@/env';
import { CHOOSE_LANG, REGISTER, NOT_FOUND } from '@/router/routes.names';
import { HTTP_BAD_REQUEST, HTTP_UNAUTHORIZED } from '@/common/axios/httpStatusCodes';

const routes: RouteRecordRaw[] = [
  {
    path: '/:recruitmentProcessId/:sessionId?',
    component: MainLayout,
    redirect: {
      name: REGISTER,
    },
    beforeEnter: async (to, from, next): Promise<void> => {
      const layoutStore = useLayoutStore();
      const sessionStore = useSessionStore();
      const settingsStore = useSettingsStore();

      try {
        layoutStore.startLoading();
        sessionStore.setCurrentRoute(to);
        await settingsStore.fetchData({
          recruitmentProcessId: Array.isArray(to.params.recruitmentProcessId)
            ? to.params.recruitmentProcessId[0]
            : to.params.recruitmentProcessId,
          sessionId: Array.isArray(to.params.sessionId)
            ? to.params.sessionId[0]
            : to.params.sessionId,
        });
        const gameHasCollections = settingsStore.gameHasCollections;
        if (to.params.sessionId) {
          await sessionStore.fetchSession(to.params.sessionId);
        }
        const sessionLocale = sessionStore.sessionLocale;
        if (gameHasCollections && to.name !== CHOOSE_LANG) {
          if (!to.params.sessionId) {
            next({
              name: CHOOSE_LANG,
              params: { recruitmentProcessId: to.params.recruitmentProcessId },
            });
            return;
          }
          if (sessionLocale === null) {
            next({
              name: CHOOSE_LANG,
              params: {
                recruitmentProcessId: to.params.recruitmentProcessId,
                sessionId: to.params.sessionId,
              },
            });
            return;
          }
        }
        next();
      } catch (e: any) {
        const statusCode = e.response?.status;

        if (statusCode === HTTP_UNAUTHORIZED) {
          next();
          return;
        }
        if (statusCode === HTTP_BAD_REQUEST) {
          next({ name: NOT_FOUND });
          return;
        }
        next(false);
        throw new Error(e.message || 'An error occurred during navigation.');
      } finally {
        layoutStore.stopLoading();
      }
    },
    children: [
      ...authRoutes,
      ...badgesRoutes,
      ...faqRoutes,
      ...welcomeRoutes,
      ...gameRoutes,
      ...finishRoutes,
      ...documentsRoutes,
      ...recordTestRoutes,
    ],
  },
  ...errorRoutes,
];

const router = createRouter({
  history: createWebHistory(env.baseUrl),
  routes,
});

export default router;
