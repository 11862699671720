import Stage from '@/domain/entities/flow/Stage';

interface VideoFiles {
  WEBM: string;
  MP4: string;
}

interface Props {
  id: string;
  type: string;
  order: number;
  completedAt: Date | null;
  title: string;
  files: VideoFiles;
}

export default class GameVideo extends Stage {
  readonly title: string;
  readonly files: VideoFiles;

  constructor({ id, type, order, completedAt, title, files }: Props) {
    super({ id, type, order, completedAt });

    this.title = title;
    this.files = files;
  }

  videos(): { url: string; type: 'video/webm' | 'video/mp4' }[] {
    return [
      {
        url: this.files.WEBM,
        type: 'video/webm',
      },
      {
        url: this.files.MP4,
        type: 'video/mp4',
      },
    ];
  }
}
