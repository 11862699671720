import { useLayoutStore } from '@/stores/layoutStore';
import { useSessionStore } from '@/stores/sessionStore';
import { useFlowStore } from '@/stores/flowStore';
import { useSettingsStore } from '@/stores/settingsStore';
import { END_OF_TIME, GAME, GAME_LAYOUT } from '@/router/routes.names';
import { HTTP_UNAUTHORIZED } from '@/common/axios/httpStatusCodes';
import type { RouteRecordRaw } from 'vue-router';

const gameRoutes: RouteRecordRaw[] = [
  {
    path: 'game',
    component: () => import('@/layouts/GameLayout.vue'),
    name: GAME_LAYOUT,
    beforeEnter: async (to, from, next) => {
      const layoutStore = useLayoutStore();
      const sessionStore = useSessionStore();
      const flowStore = useFlowStore();

      try {
        layoutStore.startLoading();
        await sessionStore.fetchSessionGameData();

        if (to.name === GAME_LAYOUT) {
          await flowStore.redirectToCurrentStage();
        }

        next();
      } catch (e: any) {
        const statusCode = e.response?.status;

        if (statusCode === HTTP_UNAUTHORIZED) {
          next();
          return;
        }
        throw new Error(e.message || 'An error occurred during navigation.');
      } finally {
        layoutStore.stopLoading();
      }
    },
    children: [
      {
        path: 'time-out',
        name: END_OF_TIME,
        component: () => import('@/views/EndOfTime.vue'),
        beforeEnter: async (to, from, next) => {
          const layoutStore = useLayoutStore();
          const settingsStore = useSettingsStore();

          const params = {
            recruitmentProcessId: Array.isArray(to.params.recruitmentProcessId)
              ? to.params.recruitmentProcessId[0]
              : to.params.recruitmentProcessId,
            sessionId: Array.isArray(to.params.sessionId)
              ? to.params.sessionId[0]
              : to.params.sessionId,
          };

          layoutStore.startLoading();
          await settingsStore.fetchPageTranslation(
            params.recruitmentProcessId,
            params.sessionId,
            END_OF_TIME
          );
          layoutStore.stopLoading();

          next();
        },
      },
      {
        path: 'stage/:stageNumber',
        name: GAME,
        component: () => import('@/views/Game.vue'),
        beforeEnter: async (to, from, next) => {
          const flowStore = useFlowStore();
          const sessionStore = useSessionStore();

          const currentStageIndex = flowStore.currentStageIndex;
          const allStagesCompleted = flowStore.areAllStagesCompleted;
          const isGameStarted = sessionStore.isGameStarted;

          if (
            !isGameStarted ||
            +to.params.stageNumber - 1 !== currentStageIndex ||
            allStagesCompleted
          ) {
            await flowStore.redirectToCurrentStage();
          }

          next();
        },
      },
    ],
  },
];

export default gameRoutes;
