import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { useSettingsStore } from '@/stores/settingsStore';
import App from '@/App.vue';
import router from '@/router';
import i18n from '@/common/i18n';
import { install } from 'vue3-recaptcha-v2';
import * as Sentry from '@sentry/vue';
import { AxiosError, isAxiosError } from 'axios';
import type { Event as SentryEvent } from '@sentry/types';
import '@/common/validation/validation';
import { env } from '@/env';

import 'primeicons/primeicons.css';
import '@/assets/styles/main.scss';

import VueEasyLightbox from 'vue-easy-lightbox';

import PrimeVue from 'primevue/config';
import Lara from '@/presets/lara';
import Tooltip from 'primevue/tooltip';

const app = createApp(App);
const pinia = createPinia();

app.use(router);
app.use(pinia);
app.use(i18n);
app.use(install, {
  sitekey: env.recaptchaKey,
});
app.use(PrimeVue, {
  unstyled: true,
  pt: Lara,
  ripple: true,
});
app.component('BaseGallery', VueEasyLightbox);
app.directive('tooltip', Tooltip);

const localeStore = useSettingsStore();
localeStore.$subscribe((mutation, state) => {
  i18n.global.locale.value = state.locale;
});

if (import.meta.env.VITE_APP_ENVIRONMENT !== 'local') {
  const ignoreErrors = [
    /navigation/gm,
    '/NetworkError.*/',
    '/Failed to fetch dynamically imported module.*/',
    '/Importing a module script failed.*/',
    '/Unable to preload CSS.*/',
  ];
  Sentry.init({
    app,
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    dsn: import.meta.env.VITE_APP_SENTRY_KEY,
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors,
    beforeSend: handleSentryBeforeSend,
  });
}

function handleSentryBeforeSend(event: any, hint: any | null): any | null {
  if (hint && hint.originalException) {
    addAxiosContextRecursive(event, hint.originalException);
  }
  return event;
}

function addAxiosContextRecursive(event: SentryEvent, error: unknown) {
  if (isAxiosError(error)) {
    addAxiosContext(event, error);

    return;
  }
  if (error instanceof AxiosError && (error as any).cause) {
    addAxiosContextRecursive(event, (error as any).cause);
  }
}

function addAxiosContext(event: SentryEvent, error: AxiosError) {
  if (error.response) {
    const contexts = { ...event.contexts };
    contexts.Axios = { Response: error.response };
    event.contexts = contexts;
  }
}

app.mount('#app');
